import React, { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { UsersAdminTable } from '../../components/molecules/usersAdminTable';
import { DefaultTemplate } from '../../components/templates/defaultTemplates';

import { ContextAplication } from '../../context';

import { Container } from './style';

export function UserAdmin() {
  const history = useHistory();

  const { handleGet } = useContext(ContextAplication);

  const [input, setInput] = useState('');

  const [users, setUsers] = useState(null);

  async function handleGetUsers() {
    const response = await handleGet('/manager-users');
    setUsers(response);
  }

  const filteredUsers = (data) => {
    if (input !== '') {
      const result = data?.filter(({ name }) =>
        name.toLowerCase().includes(input.toLowerCase())
      );

      return result;
    }
    return data;
  };

  useEffect(() => {
    handleGetUsers();
  }, []);

  return (
    <DefaultTemplate>
      <Container>
        <div className="header">
          <div>
            <h2>Painel de controle de usuários do gerenciador</h2>
            <button
              type="button"
              onClick={() => history.push('/useradmin/create')}
            >
              Cadastrar novo usuário
            </button>
          </div>
          <input
            type="text"
            placeholder="Digite sua busca"
            onChange={(e) => setInput(e.target.value)}
          />
        </div>

        <UsersAdminTable users={filteredUsers(users)} />
      </Container>
    </DefaultTemplate>
  );
}
