import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ContextAplication } from '../../context';
import { GroupAdminTable } from '../../components/molecules/groupAdminTable';
import { DefaultTemplate } from '../../components/templates/defaultTemplates';

import { Container } from './style';

export function GroupAdmin() {
  const history = useHistory();

  const { handleGet } = useContext(ContextAplication);

  const [input, setInput] = useState('');

  const [groups, setGroups] = useState(null);

  async function handleGetGroups() {
    const response = await handleGet('group');
    setGroups(response);
  }

  const filteredGroups = (data) => {
    if (input !== '') {
      const result = data?.filter(({ title }) =>
        title.toLowerCase().includes(input.toLowerCase())
      );

      return result;
    }
    return data;
  };

  useEffect(() => {
    handleGetGroups();
  }, []);

  return (
    <DefaultTemplate>
      <Container>
        <div className="header">
          <div>
            <h2>Painel de controle de grupos do gerenciador</h2>
            <button
              type="button"
              onClick={() => history.push('/groupadmin/create')}
            >
              Cadastrar novo grupo
            </button>
          </div>
          <input
            type="text"
            placeholder="Digite sua busca"
            onChange={(e) => setInput(e.target.value)}
          />
        </div>

        <GroupAdminTable groups={filteredGroups(groups)} />
      </Container>
    </DefaultTemplate>
  );
}
