import styled from 'styled-components';

export const Button = styled.button`
  align-self: end;
  padding: 0.5rem 1rem;

  background-color: var(--purple-500);
  color: white;

  border: none;
  border-radius: 5px;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  &:disabled {
    filter: brightness(0.5);
  }
`;
