import styled from 'styled-components';

export const Container = styled.div`
  
  background-color: var(--gray-700);
  border-radius: 5px;
  padding: 0;
  margin-top: 1rem;

  h2 {
    display: flex;
    justify-content: space-between;
    
    margin-bottom: 0;
    padding: 1rem;

    svg {
      transition: linear 0.2s;
      font-size: 1.5rem;
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(90deg)" : "rotate(-90deg)"} scaleX(-1);
    }
  }

  .send_recovery_email {
    background-color: var(--gray-500);
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 5px;
    padding: 1rem;
    margin: 1rem;
    .send_recovery_email_button {
      background-color: var(--purple-500) !important;
      margin: 0;
    }
  }
`;