import { useContext, useState } from 'react';

import { TiChevronRight } from 'react-icons/ti';

import { ContextAplication } from '../../../context';

import { Container } from './style';

export function UserTools({ user }) {
  const { handleCreate } = useContext(ContextAplication)

  const [showTools, setShowTools] = useState(false);

  async function handleSendRecoveryEmail() {
    if (
      window.confirm(
        `Enviar e-mail de recuperação de senha para ${user?.auth?.email}?`
      )
    ) {
      const response = await handleCreate('/auth/send-recovery', {
        email: user?.auth?.email,
      });
    }
  }

  return (
    <Container isOpen={showTools}>
      <h2 onClick={() => setShowTools(!showTools)}>
        Ferramentas
        <TiChevronRight />
      </h2>

      {showTools && (
        <>
          <div className="send_recovery_email">
            <h3>Enviar email de recuperação de senha</h3>
            <button
              className="send_recovery_email_button"
              type="button"
              onClick={handleSendRecoveryEmail}
            >
              Enviar
            </button>
          </div>
        </>
      )}
    </Container>
  );
}
