import { EmailsForm } from '../../../components/molecules/emailsForm';
import { DefaultTemplate } from '../../../components/templates/defaultTemplates';

import { Container } from './style';

export const EmailsCreate = () => {

  return (
    <DefaultTemplate>
      <Container>
        <header>
          <h1>Criar e-mail</h1>
        </header>

        <body>
          <EmailsForm isCreate={true} />
        </body>
      </Container>
    </DefaultTemplate>
  );
};
