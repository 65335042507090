import React from 'react';
import useStylesHome from './style';
// import logo from '../../assets/images/logo-white.svg';
import { DefaultTemplate } from '../../components/templates/defaultTemplates';


export const Home = () => {

  const classes = useStylesHome();

  return (
    <DefaultTemplate>
      <div className={classes.container_home}>
        <div className={classes.image_container}>
          {/* <img src={logo} width="300px" alt="Logo BlackBook" /> */}
        </div>
      </div>
    </DefaultTemplate>
  )
};
