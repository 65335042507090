import styled from 'styled-components';
 
export const UserFormComponent = styled.form`
  color: var(--white);
  padding: 2rem;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .sentFilters {
    table {
      width: 100%;
      th {
        text-align: left;
        font-size: 1.2rem;
      }
      th:after {
        color: transparent;
        content: " - ";
        display: block;
        line-height: 1rem;
      }
      tr{
        border: 2px solid var(--gray-100);
      }
      td{
        line-height: 2rem;
      }
    }
  }

  hr {
    margin-bottom: 1rem;
  }

  .select-container {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        background-color: unset;
        color: #32cd30;
      }
    }
    div {
      display: flex;
      height: 3rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid var(--gray-300);
      padding: 0 1rem;

      select {
        color: var(--white);
        width: 100%;
        background-color: var(--gray-500);
        margin-right: 1rem;

        border-radius: 5px;
        border: none;
        outline: none;
      }

      button {
        background-color: unset;
        border: none;
        outline: none;
        color: var(--red-500);
        font-size: 1.3rem;
      }
    }
  }

  .preview_html {
    padding: 0 1rem;
    div {
      padding: 1rem;
      margin-top: 1rem;
      background-color: var(--white);
      border-radius: 5px;
      max-width: 100%;
      overflow-x: auto;

      &::-webkit-scrollbar {
          width: 8px;
      }
      &::-webkit-scrollbar-thumb {
          background-color: var(--gray-900);
          border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
          background-color: var(--white);
      }
    }
  }

  .formButton {
    display: flex;
    justify-content: space-between;

    button {
      border: none;
      border-radius: 20px;
      padding: 0.5rem;
      margin-top: 2rem;
      margin-right: 1rem;
      margin-left: 1rem;
      color: white;
      font-weight: 700;
      font-size: 1rem;
      background-color: var(--green-400);
      width: 100%;
      max-width: 15rem;
      &:first-of-type {
        background-color: var(--red-500);
      }
    }
  }
`;
