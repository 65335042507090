import { useState, useEffect } from 'react';
import Select from 'react-select';

import { MdDelete, MdAdd } from 'react-icons/md';
import { FaArrowCircleDown, FaArrowCircleUp } from 'react-icons/fa';

import api from '../../../services/axios';

import { CarouselContainer, SelectComponentStyles } from './style';
import { toast } from 'react-toastify';

export function BannerCarousel({ bannerArray, setLoading, callbackFunction }) {
  const [selectedBanners, setSelectedBanners] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);

  const [bannerPlatform, setBannerPlatform] = useState('mobile');
  const [loading, setBLoading] = useState(false);

  const options = bannerArray?.map((banner) => {
    return {
      active: banner.active,
      _id: banner._id,
      label: banner.title,
      active_platform: banner.active_platform
    };
  });

  useEffect(() => {
    handleGetSelectedBanners(bannerPlatform);
  }, [bannerPlatform]);

  async function handleGetSelectedBanners(platform) {
    setBLoading(true);
    try {
      const response = await api.get(`/list-banners-by-platform/${platform}`);
      setSelectedBanners(response.data);
    } catch (error) {
      console.error(error);
    }
    setBLoading(false);
  }

  async function handleBannerAdd() {
    const temp = [...selectedBanners];
    if (temp.length > 0) {
      for (const banner of temp) {
        if (banner._id === selectedBanner._id) {
          return;
        }
      }
    }
    try {
      const activePlatforms = selectedBanner?.active_platform
        ? [...selectedBanner?.active_platform, bannerPlatform]
        : [bannerPlatform];

      const payload = {
        active_platform: activePlatforms,
        _id: selectedBanner._id,
        [getPosField()]: temp.length,
      };
      await api.put('/update-banner', payload);
    } catch (error) {
      console.error(error);
    }
    handleGetSelectedBanners(bannerPlatform);
    callbackFunction();
  }

  async function handleBannerDelete(banner) {
    const temp = [...selectedBanners];
    const index = temp.indexOf(banner);
    temp.splice(index, 1);
    try {
      const acPlatform = banner.active_platform.filter(e => e !== bannerPlatform)

      await api.put('/update-banner', {
        active_platform: acPlatform,
        _id: banner._id,
        position: null,
      });
    } catch (error) {}
    handleGetSelectedBanners(bannerPlatform);
    callbackFunction();
  }

  function handlePositionChange(from, to) {
    const field = getPosField();

    const tempArray = [...selectedBanners];

    tempArray[from] = { ...selectedBanners[from], [field]: to };
    tempArray[to] = { ...selectedBanners[to], [field]: from };
    tempArray.sort((a, b) => a[field] - b[field]);

    setSelectedBanners([...tempArray]);
  }

  async function handleCarouselSave() {
    try {
      setLoading(true);

      const field = getPosField();

      selectedBanners.map(async (banner, index) => {
        await api.put('/update-banner', {
          _id: banner._id,
          [field]: index,
        });
      });
      toast.success('Carrossel de banners salvo com sucesso!');

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao salvar o carrossel.');
    }
  }

  function getPosField() {
    return bannerPlatform === 'mobile' ? 'position' : 'position_web';
  }

  return (
    <CarouselContainer>
      <section className="bannerCarousel__filter">
        <h1 className="filter__title">Carrossel de Banners</h1>
      </section>
      <div className="platformSelector__container">
        <button
          className={bannerPlatform === 'web' ? 'unactive' : ''}
          onClick={() => setBannerPlatform('mobile')}
        >
          MOBILE
        </button>
        <button
          className={bannerPlatform === 'mobile' ? 'unactive' : ''}
          onClick={() => setBannerPlatform('web')}
        >
          WEB
        </button>
      </div>
      <section className="bannerCarousel__table">
        {!loading && (
          <>
            <div className="filterAdd">
              <Select
                styles={SelectComponentStyles}
                options={options}
                onChange={(e) => setSelectedBanner(e)}
              />
              <div className="filter__add">
                <MdAdd
                  onClick={() => handleBannerAdd()}
                />
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Ordem</th>
                  <th colSpan="2">Banner</th>
                </tr>
              </thead>
              <tbody>
                {selectedBanners &&
                  selectedBanners
                    ?.sort((a, b) => a?.[getPosField()] - b?.[getPosField()])
                    .map((banner, index) => (
                      <tr>
                        <td className="table__positionChangers">
                          <button
                            disabled={index >= selectedBanners.length - 1}
                            className="down"
                            onClick={() =>
                              handlePositionChange(index, index + 1)
                            }
                          >
                            <FaArrowCircleDown />
                          </button>
                          <button
                            disabled={index <= 0}
                            className="up"
                            onClick={() =>
                              handlePositionChange(index, index - 1)
                            }
                          >
                            <FaArrowCircleUp />
                          </button>
                        </td>
                        <td className="table__banner">{banner?.title}</td>
                        <td className="table__deleteButton">
                          <MdDelete
                            onClick={() => handleBannerDelete(banner)}
                          />
                        </td>
                      </tr>
                    ))}
                <tr className="table__buttonRow">
                  <td colSpan="3" className="table__buttonColumn">
                    <button
                      className="table__saveButton"
                      onClick={() => handleCarouselSave()}
                    >
                      Salvar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </section>
    </CarouselContainer>
  );
}
