export const bannerScripts = (type, windowWidth, bannerId) => {
  const textArea = document.querySelector(`.textArea${bannerId}`);
  const vector = document.querySelector(`.svg${bannerId}`);
  const image = document.querySelector(`.banner${bannerId}__image`);
  
  const options = {
    1: () => {
      vector.style.right = `${(windowWidth / 10) * 3.5}px`;
      const vectorRight = vector.style.right.replace('px', '');
      image.style.width = `${
        parseFloat(vectorRight) +
        (vector.getBoundingClientRect().right -
          vector.getBoundingClientRect().left) /
          2
      }px`;
      textArea.style.width = `${windowWidth - parseFloat(vectorRight) - 50}px`;
    },
    2: () => {
      vector.style.left = `${(windowWidth / 10) * 3.5}px`;
      const vectorLeft = parseFloat(vector.style.left.replace("px", ""))
      const vectorRight = vector.getBoundingClientRect().right;
      textArea.style.left = `${(windowWidth / 10) * 3.7}px`;
      image.style.width = `${vectorLeft + (vectorRight - vector.getBoundingClientRect().left) / 5}px`;
    }
  }

  return options[type]() || null
}