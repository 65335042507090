import { useContext } from 'react';
import { useFormik, Field, FieldArray, FormikProvider } from 'formik';
import { useHistory } from 'react-router-dom';
import { FaMinus, FaPlus } from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ContextAplication } from '../../../context';
import { section_type_names } from '../../../services/utils/section_names';
import { InputComponent } from '../../atoms/inputComponent';

import { UserFormComponent } from './style';


export function GroupAdminForm({ group, isCreate = false }) {
  const history = useHistory();

  const { handleCreate, handleUpdate } = useContext(ContextAplication);

  const formik = useFormik({
    initialValues: {
      title: group?.title || '',
      sections: group?.sections || [],
    },

    onSubmit: (values) => {
      isCreate ?  
        handleCreate('group', values) :
          handleUpdate(`group/${group?._id}`, values)
    },
  });

  const inputsProp = [
    {
      id: 'title',
      label: 'Nome',
      type: 'text',
      name: 'title',
      onChange: formik.handleChange,
      value: formik.values.title,
    },
  ];

  function handleFormSubmit(e) {
    e.preventDefault();
    try {
      formik.handleSubmit();
    } catch (error) {
      console.log(error);
      return;
    }
  }

  return (
    <UserFormComponent onSubmit={handleFormSubmit}>
      <FormikProvider value={formik}>
        <h2>Informações básicas</h2>
        <div>
          {inputsProp &&
            inputsProp.map((prop) => <InputComponent inputProp={prop} />)}
        </div>
        <hr />

        <FieldArray
          name="sections"
          render={(arrayHelpers) => (
            <div className="select-container">
              <div className="header">
                <h2>Grupos</h2>
                {formik.values?.sections?.length <
                  section_type_names.length && (
                  <button
                    type="button"
                    onClick={() =>
                      arrayHelpers.insert(
                        formik.values.sections.length,
                        { name: '' }
                      )
                    } // adiciona mais uma opção de select
                  >
                    <FaPlus />
                  </button>
                )}
              </div>

              {formik.values.sections &&
                formik.values.sections.map((_, index) => (
                  <>
                    <div key={index}>
                      <Field
                        component="select"
                        name={`sections[${index}].name`}
                        onChange={formik.handleChange}
                        value={formik.values.sections[index].name}
                      >
                        <option value="">Adicione</option>
                        {section_type_names?.map((section) => (
                          <option
                            // hidden={formik.values.group_id.includes(section)} // filtra as opções que vão aparecer no select
                            value={section}
                          >
                            {section}
                          </option>
                        ))}
                      </Field>

                      <button
                        type="button"
                        onClick={() => arrayHelpers.remove(index)} // remove um grupo do array
                      >
                        <FaMinus />
                      </button>
                    </div>
                    <div>
                      <label>
                        Ler
                        <Field
                          id="title"
                          type="checkbox"
                          name={`sections[${index}].read`}
                          onChange={formik.handleChange}
                          value={formik.values.sections[index].read}
                          checked={formik.values.sections[index].read}
                        />
                      </label>
                      <label>
                        Escrever
                        <Field
                          id="title"
                          label="Escrever"
                          type="checkbox"
                          name={`sections[${index}].write`}
                          onChange={formik.handleChange}
                          value={formik.values.sections[index].write}
                          checked={formik.values.sections[index].write}
                        />
                      </label>
                      <label>
                        Deletar
                        <Field
                          id="title"
                          label="Deletar"
                          type="checkbox"
                          name={`sections[${index}].delete`}
                          onChange={formik.handleChange}
                          value={formik.values.sections[index].delete}
                          checked={formik.values.sections[index].delete}
                        />
                      </label>
                    </div>
                  </>
                ))}
            </div>
          )}
        />
      </FormikProvider>

      <div className="formButton">
        <button type="button" onClick={() => history.push('/groupadmin')}>
          Cancelar
        </button>
        <button disabled={formik.isSubmitting} type="submit">
          {formik.isSubmitting ? <CircularProgress size={14}  style={{margin: 0, padding: 0}}/> : 'Enviar'}
        </button>
      </div>
    </UserFormComponent>
  );
}
