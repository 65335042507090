import { Modal, ModalContent, ModalOverlay, CloseButton, ConfirmButton } from './style';

export function GenericModalComponent({
  isOpen,
  onCloseButton,
  onConfirmButton,
  title,
  children,
  textConfirmButton,
  textCloseButton,
}) {

  if(!isOpen) {
    return null;
  }

  return (
    <Modal>
      <ModalOverlay onClick={() => onCloseButton()} />
      <ModalContent>
        <div className='divHeader'>
          <div className="divTitle">
            <h2>{title}</h2>
          </div>
          <div className="divCloseButton">
            <CloseButton onClick={() =>  onCloseButton()}>X</CloseButton>
          </div>
        </div>
        <div className="divContent">
          {children}
        </div>
        <div className="divActions">
          {textCloseButton &&
            <CloseButton onClick={() => onCloseButton()}>{textCloseButton}</CloseButton>
          }
          {textConfirmButton &&
            <ConfirmButton onClick={() => onConfirmButton()}>{textConfirmButton}</ConfirmButton>
          }
        </div>
      </ModalContent>
    </Modal>
  );
}
