import React from 'react';

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import { Routers } from './routes';
import { AplicationProvider } from './context';

import './styles/global.css';
import { FirebaseAppProvider } from 'reactfire';
import { firebaseConfig } from './services/firebase/config';

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <AplicationProvider>
        <Routers />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
      </AplicationProvider>
    </FirebaseAppProvider>
  );
}

export default App;
