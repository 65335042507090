/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';

import { ContextAplication } from '../../../context';
import { Pagination } from '../../atoms/pagination';

import { Container } from './style';
import { useContext } from 'react';

export function EmailsTemplateTable({ emailsTemplates }) {

  const history = useHistory();

  const { handleDelete } = useContext(ContextAplication);

  const [page, setPage] = useState(1);
  const [emailsTemplatesArray, setEmailsTemplatesArray] = useState(null)

  function handleEmailsTemplatesArray() {
    setEmailsTemplatesArray(emailsTemplates?.slice((page-1)*10,page*10))
  }

  async function handleDeleteTempalte(name) {

    if(window.confirm(`Deseja deletar o template ${name}`)) { 
      
      const response = await handleDelete(`templates/${name}`);
      
      history.push('emailstemplates')
    }
  }

  useEffect(() => {
    if(emailsTemplates) {
      handleEmailsTemplatesArray()
    }
  }, [emailsTemplates, page])

  return (
    <Container>
    <table>
        <tr>
          <th>Nome</th>
          <th></th>
        </tr>

        {emailsTemplatesArray && emailsTemplatesArray.map((emailTemplate) => (
          <tr>
            <td>{emailTemplate?.name}</td>
            <td style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', height: 'inherit'}}>
              <Link to={`/emailstemplates/update/${emailTemplate?.name}`}>
                <AiFillEdit />
              </Link>
              <MdDelete color="var(--red-500)" className="trash_icon" onClick={() => handleDeleteTempalte(emailTemplate?.name)} />
            </td>
          </tr>
        ))}
    </table>

    {emailsTemplates?.length > 10 && 
      <Pagination currentPage={page} totalCountOfRegisters={emailsTemplates.length} onPageChange={(e) => setPage(e)}/>
    }
    </Container>
  )
}
