import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContextAplication } from '../../../context';
import { DefaultTemplate } from '../../../components/templates/defaultTemplates';

import { Container } from './style';
import { EmailsTemplatesForm } from '../../../components/molecules/emailsTemplatesForm';

export const EmailsTemplatesUpdate = () => {
  const { handleGet } = useContext(ContextAplication);

  const [emailTemplate, setEmailTemplate] = useState();

  const { id } = useParams();

  async function handleGetTemplateEmail() {
    const response = await handleGet(`templates/${id}`);

    setEmailTemplate(response);
  }

  useEffect(() => {
    if (id) {
      handleGetTemplateEmail();
    }
  }, []);

  return (
    <DefaultTemplate>
      <Container>
        <header>
          <h1>Atualizar template e-mail</h1>
        </header>

        <body>{emailTemplate && <EmailsTemplatesForm emailTemplate={emailTemplate}/>}</body>
      </Container>
    </DefaultTemplate>
  );
};
