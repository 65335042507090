import { Container } from './style';
import { FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export function UserReferredTable( { referredUsers }) {

  return (
    <Container>
      <table>
        <tr>
          <th>Nome</th>
          <th></th>
        </tr>

        {referredUsers && referredUsers.length ? referredUsers.map((referredUser) =>
          <tr>
            <td>{referredUser?.name}</td>
            <td>
              <Link
                className="showUser__button"
                title="Ver usuário"
                to={`/users/update/${referredUser._id}`}
              >
                <FaEye />
              </Link>
            </td>
          </tr>
        ) :
          <span>Não foi indicado por nenhum usuário.</span>
        }
      </table>
    </Container>
  )
}
