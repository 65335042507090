import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ContextAplication } from '../../../context';
import { UserForm } from '../../../components/molecules/userForm';
import { DefaultTemplate } from '../../../components/templates/defaultTemplates';

import { Container } from './style';

export const UserUpdate = () => {
  const { handleGet } = useContext(ContextAplication);

  const [ user, setUser ] = useState()

  const { id } = useParams();

  async function handleGetUser() {
    setUser(null);
    try {
      const response = await handleGet(`users/${id}`);
  
      setUser(response);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(id) {
      handleGetUser();
    }
  }, [id])

  return (

    <DefaultTemplate>
      <Container>
        <header>
          <h1>Atualizar usuário</h1>
          
        </header>

        <body>
          {!!user &&
            <UserForm user={user}/>
          }
        </body>
      </Container>
    </DefaultTemplate>
  )
};
