import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ContextAplication } from '../../context';
import { DefaultTemplate } from '../../components/templates/defaultTemplates';

import { Container } from './style';
import { EmailsTemplateTable } from '../../components/molecules/emailsTemplatesTable';

export function EmailsTemplates() {
  const history = useHistory();

  const { handleGet } = useContext(ContextAplication);

  const [input, setInput] = useState('');

  const [emailsTemplates, setEmailsTemplates] = useState(null);

  async function handleGetEmails() {
    const response = await handleGet('templates');
    setEmailsTemplates(response);
  }

  const filteredEmails = (data) => {
    if (input !== '') {
      const result = data?.filter(({ title }) =>
        title.toLowerCase().includes(input.toLowerCase())
      );

      return result;
    }
    return data;
  };

  useEffect(() => {
    handleGetEmails();
  }, []);

  return (
    <DefaultTemplate>
      <Container>
        <div className="header">
          <div>
            <h2>Painel de controle de Templates de e-mails</h2>
            <button
              type="button"
              onClick={() => history.push('/emailstemplates/create')}
            >
              Cadastrar novo template
            </button>
          </div>
          <input
            type="text"
            placeholder="Digite sua busca"
            onChange={(e) => setInput(e.target.value)}
          />
        </div>

        <EmailsTemplateTable emailsTemplates={filteredEmails(emailsTemplates)}/>
      </Container>
    </DefaultTemplate>
  );
}
