import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BannersTable } from '../../components/molecules/bannersTable';
import { DefaultTemplate } from '../../components/templates/defaultTemplates';
import { BannersContainer } from './style';
import api from '../../services/axios';
import { BannerCarousel } from '../../components/molecules/bannerCarousel';
import { LoadingComponent } from '../../components/atoms/loadingComponent';
import { toast } from 'react-toastify';

export function Banners() {
  const [banners, setBanners] = useState(null);
  const [filteredBanners, setFilteredBanners] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  async function handleGetAllBanners() {
    setLoading(true)
    try {
      const response = await api.get('/list-banners/?select=title&select=active&select=active_platform');
      setBanners(response.data);
      setFilteredBanners(response.data)
    } catch (error) {}
    setLoading(false)
  }

  async function handleDeleteBanner(banner) {
    if(banner?.active) {
      alert('Este banner está selecionado no carrossel de banners, para deleta-lo remova-o do carrossel primeiro!')
      return false;
    }
    if (!window.confirm(`Deseja deletar o banner ${banner.title}?`)) return;
    setLoading(true)
    try {
      await api.delete(`/delete-banner/${banner._id}`);
      handleGetAllBanners();
      toast.success('Banner deletado com sucesso!')
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  }

  function handleFilterBanners(value) {
    if (value !== '' && value?.length >= 3 && banners) {
      const result = banners?.filter(({ title }) =>
        title.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredBanners(result)
      return 
    }
    setFilteredBanners(banners)
  }

  useEffect(() => {
    handleGetAllBanners();
  }, []);

  return (
    <DefaultTemplate>
      <BannersContainer>
        <header>
          <div>
            <h1>Banners</h1>
            <button
              className="header-button"
              type="button"
              onClick={() => history.push('banners/create')}
            >
              Criar
            </button>
          </div>
          <div>
            <input
              type="text"
              placeholder="Digite sua busca"
              onChange={(e) => handleFilterBanners(e.target.value)}
            />
          </div>
        </header>

        <body>
          <BannersTable
            banners={filteredBanners}
            handleDeleteBanner={handleDeleteBanner}
          />
        </body>

        <BannerCarousel bannerArray={banners} setLoading={setLoading} callbackFunction={handleGetAllBanners}/>
        {loading && <LoadingComponent />}
      </BannersContainer>
    </DefaultTemplate>
  );
}
