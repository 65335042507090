import { GroupAdminForm } from '../../../components/molecules/groupAdminForm';
import { DefaultTemplate } from '../../../components/templates/defaultTemplates';

import { Container } from './style';

export const GroupAdminCreate = () => {
  return (
    <DefaultTemplate>
      <Container>
        <header>
          <h1>Criar grupo</h1>
        </header>

        <body>
          <GroupAdminForm isCreate={true} group={null} />
        </body>
      </Container>
    </DefaultTemplate>
  );
};
