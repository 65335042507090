import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ActuationAreas } from '../pages/ActuationAreas';
import { CreateActuationAreas } from '../pages/ActuationAreas/CreateActuationAreas';
import { GroupAdmin } from '../pages/GroupAdmin';
import { GroupAdminCreate } from '../pages/GroupAdmin/GroupAdminCreate';
import { GroupAdminUpdate } from '../pages/GroupAdmin/GroupAdminUpdate';
import { Home } from "../pages/Home";
import { UserAdmin } from '../pages/UserAdmin';
import { UserAdminCreate } from '../pages/UserAdmin/UserAdminCreate';
import { UserAdminUpdate } from '../pages/UserAdmin/UserAdminUpdate';
import { Users } from "../pages/Users";
import { UserUpdate } from '../pages/Users/UserUpdate';
import { Notifications } from '../pages/Notifications';
import { NotificationsCreate } from '../pages/Notifications/NotificationsCreate';
import { NotificationsUpdate } from '../pages/Notifications/NotificationsUpdate';
import { Emails } from '../pages/Emails';
import { EmailsCreate } from '../pages/Emails/EmailsCreate';
import { EmailsUpdate } from '../pages/Emails/EmailsUpdate';
import { EmailsTemplates } from '../pages/EmailsTemplates';
import { EmailsTemplatesCreate } from '../pages/EmailsTemplates/EmailsTemplatesCreate';
import { EmailsTemplatesUpdate } from '../pages/EmailsTemplates/EmailsTemplateUpdate';
import { Changelog } from '../pages/Changelog';
import { Login } from '../pages/Login';
import { CouponAdmin } from '../pages/CouponAdmin';

import PrivateRoute from './privateRoutes';
import { ChangelogUpdate } from '../pages/Changelog/ChangelogUpdate';
import { AppSettings } from '../pages/AppSettings';
import { Versions } from '../pages/Versions'
import { Banners } from '../pages/Banners';
import { BannersCreate } from '../pages/Banners/CreateBanners';
import { BannersUpdate } from '../pages/Banners/UpdateBanners';

const Routers = () => (
  <Switch>
    <Route exact path='/login' component={Login} />

    <PrivateRoute exact path='/' component={Home} />

    {/* Usuários do aplicativo */}
    <PrivateRoute exact path='/users' component={Users} />
    <PrivateRoute exact path='/users/update/:id/' component={UserUpdate} />

    {/* Áreas de atuação */}
    <PrivateRoute exact path='/actuationareas' component={ActuationAreas} />
    <PrivateRoute exact path='/actuationareas/create' component={CreateActuationAreas} />

    {/* Usuários do gerenciador */}
    <PrivateRoute exact path='/useradmin' component={UserAdmin} />
    <PrivateRoute exact path='/useradmin/create' component={UserAdminCreate} />
    <PrivateRoute exact path='/useradmin/update/:id/' component={UserAdminUpdate} />

    {/* Grupos do gerenciador */}
    <PrivateRoute exact path='/groupadmin' component={GroupAdmin} />
    <PrivateRoute exact path='/groupadmin/create' component={GroupAdminCreate} />
    <PrivateRoute exact path='/groupadmin/update/:id/' component={GroupAdminUpdate} />

    {/*  Notificações */}
    <PrivateRoute exact path='/notifications' component={Notifications} />
    <PrivateRoute exact path='/notifications/create' component={NotificationsCreate} />
    <PrivateRoute exact path='/notifications/update/:id' component={NotificationsUpdate} />

    {/* E-mails */}
    <PrivateRoute exact path='/emails' component={Emails} />
    <PrivateRoute exact path='/emails/create' component={EmailsCreate} />
    <PrivateRoute exact path='/emails/update/:id' component={EmailsUpdate} />

    {/* E-mails Templates */}
    <PrivateRoute exact path='/emailstemplates' component={EmailsTemplates} />
    <PrivateRoute exact path='/emailstemplates/create' component={EmailsTemplatesCreate} />
    <PrivateRoute exact path='/emailstemplates/update/:id' component={EmailsTemplatesUpdate} />

    {/* Changelog */}
    <PrivateRoute exact path='/changelog' component={Changelog} />
    <PrivateRoute exact path='/changelog/update/:id' component={ChangelogUpdate} />

    {/* Cupons */}
    <PrivateRoute exact path='/couponadmin' component={CouponAdmin} />
    {/* <PrivateRoute exact path='/emailstemplates/create' component={EmailsTemplatesCreate} /> */}
    {/* <PrivateRoute exact path='/emailstemplates/update/:id' component={EmailsTemplatesUpdate} /> */}

    {/* Banners */}
    <PrivateRoute exact path='/banners' component={Banners} />
    <PrivateRoute exact path='/banners/create' component={BannersCreate} />
    <PrivateRoute exact path='/banners/update/:id' component={BannersUpdate} />

    {/* App Settings */}
    <PrivateRoute exact path='/appsettings' component={AppSettings} />

    {/* Version Contents */}
    <PrivateRoute exact path='/versions' component={Versions} />
  </Switch>
);

export { Routers };
