import React from 'react';
import { MenuTemplate } from '../menuTemplate';
import { Container } from './style';

export function DefaultTemplate(props) {
  return (
    <Container>
      <MenuTemplate />
      {props.children}
    </Container>
  );
}
