import { useContext } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ContextAplication } from '../../../context';
import { DefaultTemplate } from '../../../components/templates/defaultTemplates';

import { CreateActuationAreasContainer } from './style';
import { InputComponent } from '../../../components/atoms/inputComponent';

export const CreateActuationAreas = () => {
  const history = useHistory();

  const { handleCreate } = useContext(ContextAplication);

  const formik = useFormik({
    initialValues: {
      title: '',
    },

    onSubmit: async (values) => {
      try {
        await handleCreate('actuation-areas', values);

        history.push('/actuationareas');
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <DefaultTemplate>
      <CreateActuationAreasContainer>
        <header>
          <h1>Criar área de atuação</h1>
        </header>

        <body>
          <form onSubmit={formik.handleSubmit}>
            <InputComponent
              inputProp={{
                id: 'title',
                label: 'Nova área de atuação:',
                type: 'text',
                name: 'title',
                onChange: formik.handleChange,
                value: formik.values.title,
              }}
            />

            <button disabled={formik.isSubmitting} type="submit">
              {formik.isSubmitting ? (
                <CircularProgress size={14} style={{ margin: 0, padding: 0 }} />
              ) : (
                'Enviar'
              )}
            </button>
          </form>
        </body>
      </CreateActuationAreasContainer>
    </DefaultTemplate>
  );
};
