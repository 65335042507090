import { getStorage } from 'firebase/storage';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StorageProvider, useFirebaseApp } from 'reactfire';
import { BackButton } from '../../../components/atoms/backButton';
import { SubmitBanner } from '../../../components/atoms/SubmitBanner';
import { BannerHTMLPreview } from '../../../components/organisms/bannerHTMLPreview';
import { DefaultTemplate } from '../../../components/templates/defaultTemplates';
import api from '../../../services/axios';
import { UpdateBannersContainer } from './style';

export function BannersUpdate() {
  const [bannersTemplates, setBannersTemplates] = useState(null);
  const [banner, setBanner] = useState(null);
  const [isSubscriptionBanner, setIsSubscriptionBanner] = useState(false);
  const [savedImage, setSavedImage] = useState(null);
  const [imageHasBeenChanged, setImageHasBeenChanged] = useState(false);

  const appFirebase = useFirebaseApp();

  const { id } = useParams();

  async function handleGetAllBannersTemplates() {
    try {
      const response = await api.get('/banner-templates');
      setBannersTemplates(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleGetOneBanner() {
    try {
      const response = await api.get(`/list-banner/${id}/_id`);
      setBanner(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleGetAllBannersTemplates();
    handleGetOneBanner();
  }, []);

  return (
    <StorageProvider sdk={getStorage(appFirebase)}>
      <DefaultTemplate>
        <UpdateBannersContainer>
          <header className="formUpdate__header">
            <h1>Editar banner</h1>
            <BackButton route={"/banners"} />
          </header>

          {banner && (
            <div className="formUpdate__container">
              <label className="title__label">
                *Título:
                <input
                  onChange={(e) =>
                    setBanner({ ...banner, title: e.target.value })
                  }
                  className="title__input"
                  value={banner?.title}
                />
              </label>

              <label>
                Banner-template:
                <select
                  value={banner?.banner_template_id}
                  onChange={(e) =>
                    setBanner({ ...banner, banner_template_id: e.target.value })
                  }
                >
                  <option value={null}>Selecione o banner</option>
                  {bannersTemplates &&
                    bannersTemplates.map((bannerTemplate) => (
                      <option value={bannerTemplate._id}>
                        {bannerTemplate.title}
                      </option>
                    ))}
                </select>
              </label>

              <BannerHTMLPreview
                banner={banner}
                setBanner={setBanner}
                isSubscriptionBanner={isSubscriptionBanner}
                setIsSubscriptionBanner={setIsSubscriptionBanner}
                setSavedImage={setSavedImage}
                setImageHasBeenChanged={setImageHasBeenChanged}
              />
              <SubmitBanner
                banner={banner}
                bannerImage={savedImage}
                isSubscriptionBanner={isSubscriptionBanner}
                imageHasBeenChanged={imageHasBeenChanged}
              />
            </div>
          )}
        </UpdateBannersContainer>
      </DefaultTemplate>
    </StorageProvider>
  );
}
