import styled from 'styled-components';

export const UserFormComponent = styled.form`
  color: var(--white);
  padding: 2rem;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  hr {
    margin-bottom: 1rem;
  }

  div {
    display: grid;
    grid-template-columns: 1fr;
    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }
  }

  .accountStatus {
    display: grid;
    grid-template-columns: 15rem 15rem;
  }

  .formButton {
    display: flex;
    justify-content: space-between;
  }

  button {
    border: none;
    border-radius: 20px;
    padding: 0.5rem;
    margin-top: 2rem;
    margin-right: 1rem;
    margin-left: 1rem;
    color: white;
    font-weight: 700;
    font-size: 1rem;
    background-color: var(--green-400);
    width: 100%;
    max-width: 15rem;
    &:first-of-type {
      background-color: var(--red-500);
    }
  }
`;
