import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ContextAplication } from '../../../context';
import { DefaultTemplate } from '../../../components/templates/defaultTemplates';

import { Container } from './style';
import { GroupAdminForm } from '../../../components/molecules/groupAdminForm';


export const GroupAdminUpdate = () => {
  const { handleGet } = useContext(ContextAplication);

  const [group, setGroup] = useState();

  const { id } = useParams();

  async function handleGetGroup() {
    const response = await handleGet(`group/${id}`);

    setGroup(response);
  }

  useEffect(() => {
    if (id) {
      handleGetGroup();
    }
  }, []);

  return (
    <DefaultTemplate>
      <Container>
        <header>
          <h1>Atualizar grupo</h1>
        </header>

        <body>{group && <GroupAdminForm group={group} />}</body>
      </Container>
    </DefaultTemplate>
  );
};
