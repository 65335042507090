import React, { createContext } from 'react';

import { toast } from 'react-toastify';

import api from '../services/axios';

const ContextAplication = createContext();

const AplicationProvider = ({ children }) => {
  async function handleGet(path) {
    try {
      const response = await api.get(path);
      return response.data;
    } catch (error) {
      toast.error('Ocorreu um erro');
      return error;
    }
  }

  async function handleCreate(path, body) {
    try {
      const response = await api.post(path, body);
      toast.success('Criado com sucesso');
      return response?.data;
    } catch (error) {
      toast.error('Ocorreu um erro');
      return error;
    }
  }

  async function handleUpdate(path, body) {
    try {
      const response = await api.put(path, body);
      toast.success('Atualizado com sucesso');
      return response.data;
    } catch (error) {
      toast.error('Ocorreu um erro');
      return error;
    }
  }

  async function handleDelete(path) {
    try {
      const response = await api.delete(path);
      toast.success('Deletado com sucesso');
      return response;
    } catch (error) {
      toast.error('Ocorreu um erro');
      return error;
    }
  }

  const context = {
    handleGet,
    handleCreate,
    handleUpdate,
    handleDelete
  };

  return (
    <>
      <ContextAplication.Provider value={context}>
        {children}
      </ContextAplication.Provider>
    </>
  );
};

export { ContextAplication, AplicationProvider };
