/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect} from 'react';
import { IoCheckmarkCircle, IoChevronDown, IoChevronUp, IoHourglassOutline } from "react-icons/io5";
import { Pagination } from '../../atoms/pagination';
import { Container, AccordionContent, IconButton } from './style';
import { LoadingComponent } from '../../atoms/loadingComponent';

export function VersionsTable({ versions }) {
  const [page, setPage] = useState(1);
  const [versionsArray, setVersionsArray] = useState(null)
  const [openAccordions, setOpenAccordions] = useState({0: true});

  const steps = [
    {
      title: "Folha de parada",
      key: "stopSheet"
    },
    {
      title: "Rotinas",
      key: "routine"
    },
    {
      title: "Drogas",
      key: "drug"
    },
    {
      title: "Especialidades",
      key: "specialty"
    },
    {
      title: "Home",
      key: "home"
    },
    {
      title: "Busca mobile",
      key: "search"
    },
    {
      title: "Imagens da home",
      key: "imageHome"
    },
    {
      title: "Pacote de imagens iniciais",
      key: "imageZip"
    }
  ]

  function handleVersionsArray() {
    setVersionsArray(versions?.slice((page-1)*10,page*10))
  }

  const toggleAccordion = (index) => {
    setOpenAccordions((prev) => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  useEffect(() => {
    if(versions) {
      handleVersionsArray()
    }
  }, [versions, page])

  return (
    <Container>
      {versions ?
        <>
          <table>
              <tr>
                <th>Versão</th>
                <th>Criação</th>
                <th>Publicação</th>
                <th></th>
              </tr>

              {versionsArray && versionsArray.map((v, index) => (
                <>
                  <tr>
                    <td>{`${v?.version}.${v?.sub_version}`}</td>
                    <td>{new Date(v?.createdAt).toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })}</td>
                    <td>
                      { v?.publishedAt ? new Date(v?.publishedAt).toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }) : "Gerando" }
                      { v?.publishedAt ? <IoCheckmarkCircle color="green"/> : <IoHourglassOutline color="var(--red-300)"/> }
                    </td>
                    <td>
                        <IconButton onClick={() => toggleAccordion(index)}>
                          {openAccordions[index] ? <IoChevronUp /> : <IoChevronDown />}
                        </IconButton>
                      </td>
                  </tr>
                  {openAccordions[index] && (
                    <tr>
                      <td colSpan="4">
                        <AccordionContent>
                          <ul>
                            {steps.map((step, stepIndex) => (
                              <li key={stepIndex}>
                                <span>{step?.title}</span>
                                {v?.steps && v?.steps[step?.key] ? (
                                  <IoCheckmarkCircle color="green" />
                                ) : (
                                  <IoHourglassOutline color="var(--red-300)" />
                                )}
                              </li>
                            ))}
                          </ul>
                        </AccordionContent>
                      </td>
                    </tr>
                  )}
                
                </>
              ))}
          </table>

          {versions?.length > 10 && 
            <Pagination currentPage={page} totalCountOfRegisters={versions.length} onPageChange={(e) => setPage(e)}/>
          }
        
        </>
        :
        <LoadingComponent />
      }
    </Container>
  )
}
