import { ref, uploadBytes } from 'firebase/storage';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useStorage } from 'reactfire';

import api from '../../../services/axios';
import { LoadingComponent } from '../loadingComponent';

import { Button } from './style.js';

export function SubmitBanner({ banner, isSubscriptionBanner, bannerImage, imageHasBeenChanged }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const storage = useStorage()
  const newRef = ref(
    storage,
    `bannerImages/${banner._id
      .normalize("NFD")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z.])/g, "")}.png`
  );

  async function handleSubmitBanner() {
    if (banner?.active) {
      if (
        !window.confirm(
          `Este Banner pertence ao carrossel de banners e alterá-lo irá refletir no carrossel. Deseja prosseguir?`
        )
      ) {
        return;
      }
    }
    setIsSubmitting(true);

    try {
      if(imageHasBeenChanged){
        await uploadBytes(newRef, bannerImage);
      }
      await api.put('/update-banner', {
        ...banner,
        is_subscrition_banner: isSubscriptionBanner,
      });
      toast.success('Banner salvo com sucesso!');
    } catch (error) {
      console.log(error);
      toast.error(error?.response.data.message);
    }

    setIsSubmitting(false);
  }
  return (
    <>
      <Button
        disabled={isSubmitting}
        className="submit__button"
        type="button"
        onClick={handleSubmitBanner}
      >
        Salvar
      </Button>
      {isSubmitting && <LoadingComponent />}
    </>
  );
}
