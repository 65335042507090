import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BackButton } from '../../../components/atoms/backButton';
import { DefaultTemplate } from "../../../components/templates/defaultTemplates"
import api from "../../../services/axios"
import { BannersCreateContainer } from "./style"

export function BannersCreate() {
  const [bannersTemplates, setBannersTemplates] = useState(null);
  const [banner, setBanner] = useState(null);

  const history = useHistory();

  async function handleGetAllBannersTemplates() {
    try {
      const response = await api.get('/banner-templates')
    setBannersTemplates(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  async function handleSubmitBanner() {
    try {
      const response = await api.post('/create-banner', banner)
      history.push(`/banners/update/${response.data._id}`)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetAllBannersTemplates()
  }, [])

  return (
    <DefaultTemplate>
      <BannersCreateContainer>
        <header className="form__header">
          <h1>Criar banner</h1>
          <BackButton route={'/banners'} />
        </header>

        <body className="form__container">
          <label className="title__label">
            Título*:
            <input
              onChange={(e) => setBanner({ ...banner, title: e.target.value })}
              className="title__input"
              value={banner?.title}
            />
          </label>

          <label>
            Banner-template:
            <select
              value={banner?.banner_template_id}
              onChange={(e) => setBanner({ ...banner, banner_template_id: e.target.value })}
            >
              <option value={null}>Selecione o banner</option>
              {bannersTemplates && bannersTemplates.map((bannerTemplate) => (
                <option value={bannerTemplate._id}>{bannerTemplate.title}</option>
              ))}
            </select>
          </label>
          <button
            onClick={() => handleSubmitBanner()}
            className="save__button"
          >
            Salvar
          </button>
        </body>
      </BannersCreateContainer>
    </DefaultTemplate>
  )
}
