import Lottie from 'react-lottie';

import loadingCircle from '../../../assets/lotties/loading-circle.json';

import { Container } from './style';

export function LoadingComponent() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingCircle,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container>
      <Lottie options={defaultOptions} height={200} width={200} />
    </Container>
  );
}
