import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CouponAdminTable } from '../../components/molecules/couponAdminTable';
import { DefaultTemplate } from '../../components/templates/defaultTemplates';

import { Container } from './style';

const mockCoupons = [
  {
    name: 'Juan Carlos',
    email: 'juancarlos@email.com',
    was_used: true,
    date: '13/12/2021'
  },
  {
    name: 'Juan Carlos',
    email: 'juancarlos@email.com',
    was_used: false,
    date: '13/12/2021'
  },
  {
    name: 'Juan Carlos',
    email: 'juancarlos@email.com',
    was_used: true,
    date: '13/12/2021'
  },
  {
    name: 'Juan Carlos',
    email: 'juancarlos@email.com',
    was_used: false,
    date: '13/12/2021'
  },
]

export function CouponAdmin() {
  const history = useHistory();

  const [input, setInput] = useState('');

  const filteredCoupons = (data) => {
    if (input !== '') {
      const result = data?.filter(({ name }) =>
        name.toLowerCase().includes(input.toLowerCase())
      );

      return result;
    }
    return data;
  };

  return (
    <DefaultTemplate>
      <Container>
        <div className="header">
          <div>
            <h2>Painel de controle de cupons</h2>
            <button
              type="button"
              onClick={() => history.push('/couponadmin/create')}
            >
              Cadastrar novo cupom
            </button>
          </div>
          <input
            type="text"
            placeholder="Digite sua busca"
            onChange={(e) => setInput(e.target.value)}
          />
        </div>

        <CouponAdminTable coupons={mockCoupons} />
      </Container>
    </DefaultTemplate>
  );
}
