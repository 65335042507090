import { useEffect, useRef, useState } from 'react';
import { Container, BannerPreviewBanners } from "./style";
import api from '../../../services/axios';
import { bannerScripts } from '../../../services/utils/banner_scripts';

let htmlToReact = require('html-to-react').Parser;

export function BannerTemplatePreview({ banner, setBanner }) {
  const content = new htmlToReact();
  const containerRef = useRef([]);

  const [bannerTemplate, setBannerTemplate] = useState(null);
  const [previewContent, setPreviewContent] = useState(null);

  async function handleGetBannerTemplate(id) {
    try {
      if (id === bannerTemplate?._id) {
        return false;
      }
      const response = await api.get(`/banner-templates/${id}`);

      setBannerTemplate(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleParseContent() {
    if (await handleGetBannerTemplate(banner.banner_template_id)) {
      return;
    }

    let temp = await bannerTemplate?.html
      .replace('{{{text}}}', banner?.banner_text ? banner.banner_text : '')
      .replace(
        '{{{link}}}',
        banner?.banner_link_id
          ? `%7B%7Blink=%22${banner.banner_link_id}%22%7D%7D`
          : '{{{link}}}'
      )
      .replace(
        '{{{link}}}',
        banner?.banner_external_link
          ? banner.banner_external_link
          : ''
      )
      .replace('{{{image}}}', banner.banner_image);

    setBanner({ ...banner, content: temp });
    setPreviewContent(temp);
  }

  useEffect(() => {
    handleParseContent();
  }, [banner.banner_text, banner.banner_image, banner.banner_template_id,  bannerTemplate]);

  const [bannerWidth, setBannerWidth] = useState(360);

  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      setBannerWidth(entry.contentRect.width);
    });
  });
  

  function bannerCorrection() {
    for (let bannerNumber = 1; bannerNumber <= 4; bannerNumber++) {
      const bannerElement = document.querySelector(`.banner${bannerNumber}Link`)
      const type = bannerElement?.dataset?.bannertype;
      const windowWidth = containerRef.current[bannerNumber].getBoundingClientRect().width;
  
      if(type) {
  
        bannerScripts(type, windowWidth, bannerNumber);
      } 
    }
  }

  useEffect(() => {
    if(previewContent) {

      bannerCorrection();
      resizeObserver.observe(containerRef.current[1]);
    }
  }, [banner, bannerWidth, previewContent]);

  function handleMultipleBanners(number){
    if(previewContent){
      let temp = previewContent
      const parsedBanner = temp.replaceAll('{{{id}}}', number)
      return content.parse(parsedBanner)
    }
  }

  return (
    <BannerPreviewBanners>
      <h2>Mobile mínimo: </h2>
      <Container ref={(ref) => containerRef.current["1"] = ref} dimensions={340}>{previewContent && handleMultipleBanners(1)}</Container>
      <h2>Mobile máximo: </h2>
      <Container ref={(ref) => containerRef.current["2"] = ref} dimensions={450}>{previewContent && handleMultipleBanners(2)}</Container>
      <h2>Web mínimo: </h2>
      <Container ref={(ref) => containerRef.current["3"] = ref} dimensions={700}>{previewContent && handleMultipleBanners(3)}</Container>
      <h2>Web máximo: </h2>
      <Container ref={(ref) => containerRef.current["4"] = ref} dimensions={1400}>{previewContent && handleMultipleBanners(4)}</Container>
    </BannerPreviewBanners>
  );
}
