import styled from 'styled-components';

export const PaginationItemComponent = styled.button`
  height: 20px;
  background-color: ${({isCurrent}) => isCurrent ? 'var(--purple-500)' : 'var(--gray-700)'};
  border-radius: 5px;
  border: none;
  font-size: 0.8rem;
  color: var(--white);
  margin: 0 2px;
  &:hover {
    background-color: var(--gray-900);
  }
`;
