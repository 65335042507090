import { useContext, useEffect, useState } from 'react';
import { useFormik, Field, FieldArray, FormikProvider } from 'formik';
import { useHistory } from 'react-router-dom';

import { FaMinus, FaPlus } from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';

import { InputComponent } from '../../atoms/inputComponent';
import { ContextAplication } from '../../../context';

import { UserFormComponent } from './style';

export function UserAdminForm({ user, isCreate = false }) {
  const { handleCreate, handleGet, handleUpdate } =
    useContext(ContextAplication);

  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);

  const [groupsArray, setGroupsArray] = useState(null);

  async function handleGetGroups() {
    const response = await handleGet('group');
    setGroupsArray(response);
  }

  useEffect(() => {
    if (!groupsArray) {
      handleGetGroups();
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      name: user?.name || '',
      email: user?.email || '',
      password: user?.password || '',
      group_ids:
        user?.group_ids?.map((group) => {
          return group._id;
        }) || [],
    },

    onSubmit: async (values) => {
      try {
        let response = null;
        isCreate
          ? (response = await handleCreate('manager-users', values))
          : (response = await handleUpdate(
              `manager-users/${user?._id}`,
              values
            ));

        return response;
      } catch (error) {
        console.error(error);
      }
    },
  });

  const inputPessoal = [
    {
      id: 'name',
      label: 'Nome',
      type: 'text',
      name: 'name',
      onChange: formik.handleChange,
      value: formik.values.name,
    },
    {
      id: 'email',
      label: 'E-mail',
      type: 'email',
      name: 'email',
      onChange: formik.handleChange,
      value: formik.values.email,
    },
    {
      id: 'password',
      label: 'Senha',
      type: showPassword ? 'text' : 'password',
      name: 'password',
      onChange: formik.handleChange,
      value: formik.values.password,
    },
    {
      id: 'show-password',
      label: 'Mostrar senha',
      type: 'checkbox',
      name: 'show-password',
      onChange: () => setShowPassword(!showPassword),
      value: showPassword,
    },
  ];

  function handleFormSubmit(e) {
    e.preventDefault();
    try {
      formik.handleSubmit();
    } catch (error) {
      console.log(error);
      return;
    }
  }

  return (
    <UserFormComponent onSubmit={handleFormSubmit}>
      <FormikProvider value={formik}>
        <h2>Informações básicas</h2>
        <div>
          {inputPessoal &&
            inputPessoal.map((prop) => <InputComponent inputProp={prop} />)}
        </div>
        <hr />

        <FieldArray
          name="group_ids"
          render={(arrayHelpers) => (
            <div className="select-container">
              <div className="header">
                <h2>Grupos</h2>
                {formik.values?.group_ids?.length < groupsArray?.length && (
                  <button
                    type="button"
                    onClick={() =>
                      arrayHelpers.insert(formik.values.group_ids.length, null)
                    } // adiciona mais uma opção de select
                  >
                    <FaPlus />
                  </button>
                )}
              </div>
              {formik.values.group_ids &&
                formik.values.group_ids.map((_, index) => (
                  <div key={index}>
                    <Field
                      component="select"
                      name={`group_ids[${index}]`}
                      onChange={formik.handleChange}
                      value={formik.values.group_ids[index]}
                    >
                      <option value="">Adicione</option>
                      {groupsArray?.map((group) => (
                        <option
                          hidden={formik.values.group_ids?.includes(group._id)} // filtra as opções que vão aparecer no select
                          value={group._id}
                        >
                          {group.title}
                        </option>
                      ))}
                    </Field>
                    <button
                      type="button"
                      onClick={() => arrayHelpers.remove(index)} // remove um grupo do array
                    >
                      <FaMinus />
                    </button>
                  </div>
                ))}
            </div>
          )}
        />
      </FormikProvider>

      <div className="formButton">
        <button type="button" onClick={() => history.push('/useradmin')}>
          Cancelar
        </button>
        <button disabled={formik.isSubmitting} type="submit">
          {formik.isSubmitting ? <CircularProgress size={14}  style={{margin: 0, padding: 0}}/> : 'Enviar'}
        </button>
      </div>
    </UserFormComponent>
  );
}
