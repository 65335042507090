import styled from 'styled-components';

export const SpecialtiesContainerStyles = styled.div`
  .specialties__container, .activeprinciple__container {
    background-color: var(--gray-500);
    padding: 1rem;
    border: 1px solid var(--purple-500);
    display: flex;
    flex-direction: column;
  }

  .specialty__list, .activeprinciple__list {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .add__button {
    display: flex;
    align-items: center;
    background-color: unset;
    border: none;
    color: var(--green-400);
    font-size: 1.5rem;
    &:hover {
      filter: brightness(0.8);
    }
    &:disabled {
      filter: brightness(0.8);
      cursor: not-allowed;
    }
  }

  .save__button {
    border: none;
    padding: 1rem;
    border-radius: 5px;
    color: white;
    background-color: var(--purple-500);
    align-self: flex-end;
    &:hover {
      filter: brightness(0.9);
    }
  }
`;


export const SelectComponentStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'var(--gray-700)',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    marginTop: '5px',
    transition: 'all .2s linear',

    ':hover': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'var(--gray-900)',
      cursor: 'pointer'
    },

    ':focus': {
      border: 'none',
      boxShadow: 'none',
    },

    ':active': {
      border: 'none',
      boxShadow: 'none',
    },

    ':focus-visible': {
      border: 'none',
      boxShadow: 'none',
    },

    ':focus-within': {
      border: 'none',
      boxShadow: 'none',
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: 'white',
      color: 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',
      borderRadius: '0',

      ':active': {
        ...styles[':active'],
        backgroundColor: 'var(--gray-100)',
      },

      ':hover': {
        ...styles[':hover'],
        backgroundColor: 'var(--gray-100)',
      },
    };
  },
  placeholder: (styles) => ({ ...styles, color: 'var(--gray-100)' }),
  singleValue: (styles, { data }) => ({ ...styles, color: 'white' }),
  container: (styles) => ({...styles, width: '100%'}),
  input: (styles) => ({...styles, color: 'white'}),
};