import styled from 'styled-components';

export const Table = styled.div`
  background-color: var(--gray-500);
  color: var(--white);
  padding: 1.2rem;
  border-radius: 5px;

  div {
    display: grid;
    grid-template-columns: 8fr 0fr 0fr 0fr;
    border-bottom: 1px solid var(--gray-300);
    align-items: center;
    &:first-child {
        padding: 0 0 15px 0;
      }
    
    span {
        padding: 8px 4px;
      }

    strong {
      font-size: 1.2rem;
      padding: 0 4px;
    }

      button {
        background-color: unset;
        border: none;
        height: 2rem;
        width: 2rem;
        color: var(--purple-900);
        font-size: 1.5rem;
        display: flex;
        align-items: center;
      }
  }
`;
