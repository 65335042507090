/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useEffect } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { Pagination } from '../../atoms/pagination';
import { Table } from './style';

export function ActuationAreasTable({ actuationAreas }) {
  const [page, setPage] = useState(1);
  const [actuationAreasArray, setActuationAreasArray] = useState(null)

  function handleActuationAreasArray() {
    setActuationAreasArray(actuationAreas?.slice((page-1)*10,page*10))
  }

  useEffect(() => {
    if(actuationAreas) {
      handleActuationAreasArray()
    }
  }, [actuationAreas, page])

  return (
    <>
    <Table>
        <div>
          <strong>Título</strong>
          <strong></strong>
        </div>

        {actuationAreasArray && actuationAreasArray.map((actuationArea) => (
          <div>
            <span>{actuationArea?.title}</span>
            <span>
              <button>
                <AiFillEdit />
              </button>
            </span>
          </div>
        ))}
    </Table>

    {actuationAreas?.length > 10 && 
      <Pagination currentPage={page} totalCountOfRegisters={actuationAreas.length} onPageChange={(e) => setPage(e)}/>
    }
    </>
  )
}
