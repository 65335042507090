import { useState } from 'react';
import { StorageProvider, useFirebaseApp } from 'reactfire';
import { getStorage } from 'firebase/storage';

import { DefaultTemplate } from '../../components/templates/defaultTemplates';
import { SpecialtiesContainer } from './SpecialtiesContainer';

import { AppSettingsContainer } from './style';

export function AppSettings() {
  const [type, setType] = useState('routine');

  const appFirebase = useFirebaseApp();
  
  return (
    <StorageProvider sdk={getStorage(appFirebase)}>
      <DefaultTemplate>
        <AppSettingsContainer>
          <div className="header">
            <div className="header__title">
              <h2>Configuração da Home</h2>
            </div>

            <div className="platformSelector__container">
              <button
                className={type === 'drug' ? 'unactive' : ''}
                onClick={() => setType('routine')}
              >
                ROTINAS
              </button>
              <button
                className={type === 'routine' ? 'unactive' : ''}
                onClick={() => setType('drug')}
              >
                DROGAS
              </button>
            </div>

            <SpecialtiesContainer route={type} />
          </div>
        </AppSettingsContainer>
      </DefaultTemplate>
    </StorageProvider>
  );
}
