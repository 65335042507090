/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useEffect } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import { Pagination } from '../../atoms/pagination';
import { Container } from './style';

export function UsersAdminTable({ users }) {
  const [page, setPage] = useState(1);
  const [userArray, setUserArray] = useState(null)

  function handleUsersArray() {
    setUserArray(users?.slice((page-1)*10,page*10))
  }

  useEffect(() => {
    if(users) {
      handleUsersArray()
    }
  }, [users, page])

  return (
    <Container>
    <table>
        <tr>
          <th>Nome</th>
          <th>E-mail</th>
          <th>Grupos</th>
          <th></th>
        </tr>

        {userArray && userArray.map((user) => (
          <tr>
            <td>{user?.name}</td>
            <td>{user?.email}</td>
            <td>
              {user?.group_ids?.map(group => (
                `${group.title}, `
              ))}</td>
            <td>
              <Link to={`/useradmin/update/${user?._id}`}>
                <AiFillEdit />
              </Link>
            </td>
          </tr>
        ))}
    </table>

    {users?.length > 10 && 
      <Pagination currentPage={page} totalCountOfRegisters={users.length} onPageChange={(e) => setPage(e)}/>
    }
    </Container>
  )
}
