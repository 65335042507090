/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { Pagination } from '../../atoms/pagination';
import { Container } from './style';

export function NotificationsTable({ notifications }) {
  const [page, setPage] = useState(1);
  const [notificationsArray, setNotificationsArray] = useState(null)

  function handleNotificationsArray() {
    setNotificationsArray(notifications?.slice((page-1)*10,page*10))
  }

  useEffect(() => {
    if(notifications) {
      handleNotificationsArray()
    }
  }, [notifications, page])

  function parseDate(date) {
    const parsedDate = new Date(date).toLocaleDateString('pt-BR', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'})

    return `${parsedDate}`;
  }

  return (
    <Container>
    <table>
        <tr>
          <th>Título</th>
          <th>Data</th>
          <th></th>
        </tr>

        {notificationsArray && notificationsArray.map((notification) => (
          <tr>
            <td>{notification?.title}</td>
            <td>{parseDate(notification?.date)}</td>
            <td>
              <Link to={`/notifications/update/${notification?._id}`}>
                <AiFillEdit />
              </Link>
            </td>
          </tr>
        ))}
    </table>

    {notifications?.length > 10 && 
      <Pagination currentPage={page} totalCountOfRegisters={notifications.length} onPageChange={(e) => setPage(e)}/>
    }
    </Container>
  )
}
