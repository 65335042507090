import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';

export const Container = styled.div`
  max-width: 300px;
  background-color: var(--gray-900);
  color: var(--white);

  header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem;
    font-size: 2rem;
    font-weight: 500;

    img {
      height: 2rem;
      margin-right: 1rem;
    }
  }

  hr {
    background-color: var(--gray-300);
  }

  a {
    &.Mui-selected {
      background-color: unset;
      border-right: 2px solid var(--purple-500);
    }
  }

  .MuiCollapse-entered {
    padding-left: 2rem;
  }

  .MuiCollapse-container {
    transition: all 0.3s;
  }
`;

export const ListItemComponent = styled(ListItem)`
  .chevron {
    min-width: 1.4rem;
      transition: linear 0.2s;
      font-size: 1.5rem;
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(90deg)" : "rotate(-90deg)"} scaleX(-1);
    }
`