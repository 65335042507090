import styled from 'styled-components';

export const UpdateBannersContainer = styled.div`
  width: 100%;
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  padding: 3rem;
  background-color: var(--gray-700);

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-900);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-700);
  }

  .formUpdate__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    margin-bottom: 2rem;

    h1 {
      font-size: 2rem;
      color: var(--white);
      margin-bottom: 0.5rem;
    }
  }

  .formUpdate__container {
    padding: 1rem;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: var(--gray-500);

    width: 100%;
    border-radius: 5px;

    .title__label {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .title__input {
      font-size: 1rem;
      color: white;
      margin-bottom: 1rem;
      border: none;
      background-color: unset;
      border-bottom: 1px solid var(--gray-100);
      &:focus {
        outline: none;
      }
    }

    select {
      color: white;
      border: none;
      border-bottom: 1px solid var(--gray-100);
      width: 100%;
      background-color: unset;
      margin: 1rem 0;
      font-size: 1rem;
      &:focus {
        outline: none;
      }

      option {
        color: black;
      }
    }

  }
`
