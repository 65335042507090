import styled from 'styled-components';

export const BackButtonContainer = styled.button`
  color: white;
  background-color: transparent;

  border: solid 1px var(--gray-300);
  border-radius: 5px;

  height: 1.5rem;

  &:hover {
    border-color: var(--gray-900);
  }
`;
