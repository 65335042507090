import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--gray-500);
  color: white;
  padding: 1.2rem;
  border-radius: 5px;
  width: 100%;

  table {
    width: 100%;
    
    tr {
      height: 3rem;
      border-bottom: 1px solid var(--gray-300);
      vertical-align: middle;

      th {
        font-size: 1.2rem;
      }

      th, td {
        vertical-align: middle;
        text-align: left;
      }

      svg {
        font-size: 1.2rem;
        margin-left: 10px;
        vertical-align: text-top;
      }

      a:visited {
        color: #3104ee;
      }
    }
  }
`;

export const AccordionContent = styled.div`
  background-color: var(--gray-700);
  padding: 1rem;
  border-radius: 4px;
  margin-top: 0.5rem;

  ul {
    list-style: none;
    padding-left: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      svg {
        margin-right: 8px;
      }

      span {
        font-size: 1rem;
      }
    }
  }
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--gray-300);
  }
`;