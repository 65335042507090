import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  inset: 0;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 1000;

  height: 100vh;
  width: 100vw;

  background-color: rgba(0,0,0,0.5);
`;
