/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useEffect } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import { Pagination } from '../../atoms/pagination';
import { Container } from './style';

export function GroupAdminTable({ groups }) {
  const [page, setPage] = useState(1);
  const [groupArray, setGroupArray] = useState(null)

  function handleUsersArray() {
    setGroupArray(groups?.slice((page-1)*10,page*10))
  }

  useEffect(() => {
    if(groups) {
      handleUsersArray()
    }
  }, [groups, page])

  return (
    <Container>
    <table>
        <tr>
          <th>Nome</th>
          <th></th>
        </tr>

        {groupArray && groupArray.map((group) => (
          <tr>
            <td>{group?.title}</td>
            
            <td>
              <Link to={`/groupadmin/update/${group?._id}`}>
                <AiFillEdit />
              </Link>
            </td>
          </tr>
        ))}
    </table>

    {groups?.length > 10 && 
      <Pagination currentPage={page} totalCountOfRegisters={groups.length} onPageChange={(e) => setPage(e)}/>
    }
    </Container>
  )
}
