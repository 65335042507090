import { useState } from "react";
import { BannerTemplatePreview } from "../../molecules/bannerTemplatePreview";
import { ModalLink } from "../../molecules/modalLink";
import { BannerHTMLPreviewContainer } from "./style";

export function BannerHTMLPreview({
  banner,
  setBanner,
  isSubscriptionBanner,
  setIsSubscriptionBanner,
  setSavedImage,
  setImageHasBeenChanged
}) {
  const [openModalLink, setOpenModalLink] = useState(false);
  const [imagePreview, setimagePreview] = useState(process.env.REACT_APP_FIREBASE_URL + 'bannerImages%2F' + banner._id + '.png?alt=media')

  async function handleSaveImage(image) {
    setImageHasBeenChanged(true)
    setSavedImage(image)
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      setimagePreview(`data:image/png;base64, ${base64String}`)
    };
    reader.readAsDataURL(image);
  }

  return (
    <>
      <BannerHTMLPreviewContainer>
        <div className="inputs__div">
          <label>
            Texto:
            <input
              className="input__text"
              value={banner?.banner_text}
              type="text"
              onChange={(e) =>
                setBanner({ ...banner, banner_text: e.target.value })
              }
            />
          </label>

          <div className="dualInput">
            <label>
              Imagem (PNG):
              <input
                className="input__image"
                type="file"
                accept=".png"
                onChange={(e) => handleSaveImage(e.target.files[0])}
              />
              {banner?.banner_image ? (
                <img
                  style={{ width: "50px", height: "50px" }}
                  src={imagePreview}
                  alt="Banner"
                  onError={(e) => {
                    e.target.onerror = null;
                  }}
                />
              ) : (
                "Nenhuma imagem selecionada."
              )}
            </label>

            <label className="input__checkbox">
              Banner para novos assinantes:
              <input
                checked={isSubscriptionBanner}
                type="checkbox"
                onChange={(e) => setIsSubscriptionBanner(e.target.checked)}
              />
            </label>
          </div>

          {!isSubscriptionBanner && (
            <button
              className="button__link"
              onClick={() => setOpenModalLink(true)}
            >
              Adicionar link
            </button>
          )}
          {openModalLink && (
            <ModalLink
              setOpenModalLink={setOpenModalLink}
              bannerId={banner._id}
              externalLinkHref={banner.banner_external_link || null}
              setBannerLink={(newBanner) => setBanner(newBanner)}
              banner={banner}
            />
          )}
        </div>
      </BannerHTMLPreviewContainer>
      {imagePreview && (
        <BannerTemplatePreview
          banner={{ ...banner, banner_image: imagePreview }}
          setBanner={setBanner}
        />
      )}
    </>
  );
}
