import { useContext } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import { ContextAplication } from '../../../context';
import { InputComponent } from '../../atoms/inputComponent';

import { UserFormComponent } from './style';
import { UserTools } from '../../atoms/userTools';
import { UserReferrals } from '../../atoms/userReferrals';

export function UserForm({ user }) {
  const history = useHistory();
  const { handleUpdate } = useContext(ContextAplication);

  const formik = useFormik({
    initialValues: {
      name: user?.name,
      document: user?.document,
      country_phone: user?.country_phone,
      country_dial: user?.country_dial,
      phone_number: user?.phone_number,
      image_profile_url: user?.image_profile_url,
      crm_number: user?.crm_number,
      actuation_area_id: user?.actuation_area_id,
      college: user?.college,
      graduation_year: user?.graduation_year,
      is_professional: user?.is_professional
    },

    onSubmit: values => {
        handleUpdate(`users/${user._id}`, values);
    }
  });

  const formikAuth = useFormik({
    initialValues: {
      account_confirmed: !!user?.auth.account_confirmed,
      canceled: !!user?.auth.canceled,
      canceled_at: user?.auth.canceled_at,
      created_at: user?.auth.created_at,
      email: user?.auth.email,
      updated_at: user?.auth.updated_at,
    },

    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  // Array de propriedades do input, é passado para o componente InputComponent O ARRAY DEFINE A ORDEM DOS INPUTS
  const inputPessoal = [

    // -------------  Dados Pessoais -------------

    // Input do nome
    { id: 'name', label: 'Nome', type: 'text', name: 'name', onChange: formik.handleChange, value: formik.values.name },

    // Foto
    { id: 'image_profile_url', label: 'URL da foto de perfil', type: 'text', name: 'image_profile_url', onChange: formik.handleChange, value: formik.values.image_profile_url },

    // Documentos pessoais
    { id: 'document', label: 'Documento', type: 'text', name: 'documento', onChange: formik.handleChange, value: formik.values.document },
  ]

  const inputContato = [
    //-------------- Contato --------------
    // E-mail
    { id: 'email', label: 'E-mail', name: 'email', type: 'email', onChange: formikAuth.handleChange, value: formikAuth.values.email },
    { id: 'country_phone', label: 'País de origem', type: 'text', name: 'country_phone', onChange: formik.handleChange, value: formik.values.country_phone },
    { id: 'phone_number', label: 'Telefone', type: 'text', name: 'phone_number', onChange: formik.handleChange, value: formik.values.phone_number },
    // E-MAIL AQUI
  ]

  const inputProfissional = [
    // -------------  Dados profissionais ou estudantis -------------

    // Registro profissional
    { id: 'crm_number', label: 'Número do registro profissional', type: 'text', name: 'crm_number', onChange: formik.handleChange, value: formik.values.crm_number },

    // Profissão ou curso
    { id: 'actuation_area_id', label: 'Profissão ou curso', type: 'text', name: 'actuation_area_id', onChange: formik.handleChange, value: formik.values.actuation_area_id },

    // Instituição de ensino e ano de formação
    { id: 'college', label: 'Instituição de ensino', type: 'text', name: 'college', onChange: formik.handleChange, value: formik.values.college },
    { id: 'graduation_year', label: 'Ano de formação', tyoe: 'text', name: 'graduation_year', onChange: formik.handleChange, value: formik.values.graduation_year },
  ]

  const inputAuth = [
    // Data de cancelamento
    { id: 'canceled_at', label: 'Data de cancelamento', name: 'canceled_at', type: 'text', onChange: formikAuth.handleChange, value: formikAuth.values.canceled_at },

    // Data de criação
    { id: 'created_at', label: 'Data de criação', name: 'created_at', type: 'text', onChange: formikAuth.handleChange, value: formikAuth.values.created_at },

    // Data de atualização
    { id: 'updated_at', label: 'Data de atualização', name: 'updated_at', type: 'text', onChange: formikAuth.handleChange, value: formikAuth.values.updated_at }
  ]

  const inputStatus = [
    // Conta confirmada 
    { id: 'account_confirmed', label: 'Conta confirmada', name: 'account_confirmed', type: 'radio', onChange: formikAuth.handleChange, checked: formikAuth.values.account_confirmed ? true : false, value: true },

    // Conta não confirmada
    { id: 'account_unconfirmed', label: 'Conta não confirmada', name: 'account_confirmed', type: 'radio', onChange: formikAuth.handleChange, checked: formikAuth.values.account_confirmed ? false : true, value: false },

    // Conta cancelada
    { id: 'canceled', label: 'Conta cancelada', name: 'canceled', type: 'radio', onChange: formikAuth.handleChange, checked: formikAuth.values.canceled ? true : false, value: true },

    // Conta ativa
    { id: 'active', label: 'Conta ativa', name: 'canceled', type: 'radio', onChange: formikAuth.handleChange, checked: formikAuth.values.canceled ? false : true, value: false },
  ]

  const inputProfileType = [
    // Estudante
    { id: 'student_profile', label: 'Estudante', name: 'is_professional', type: 'radio', onChange: formik.handleChange, checked: formik.values.is_professional ? false : true, value: true },

    // Profissional
    { id: 'professional_profile', label: 'Profissional', name: 'is_professional', type: 'radio', onChange: formik.handleChange, checked: formik.values.is_professional ? true : false, value: false },
  ]

  function handleFormSubmit(e) {
    e.preventDefault();
    try {
      formik.handleSubmit();
      formikAuth.handleSubmit();
    }
    catch (error) {
      console.log(error);
      return;
    }
  }

  return (
    <UserFormComponent onSubmit={handleFormSubmit}>
      <h2>Informações básicas</h2>
      <div>
        {inputPessoal &&
          inputPessoal.map((prop) => <InputComponent inputProp={prop} />)}
      </div>
      <hr />
      <h2>Contato</h2>
      <div>
        {inputContato &&
          inputContato.map((prop) => <InputComponent inputProp={prop} />)}
      </div>
      <hr />
      <h2>Dados profissionais ou estudantis</h2>
      <div className="accountStatus">
        {inputProfileType &&
          inputProfileType.map((prop) => <InputComponent inputProp={prop} />)}
      </div>
      <div>
        {inputProfissional &&
          inputProfissional.map((prop) => <InputComponent inputProp={prop} />)}
      </div>
      <hr />
      <h2>Dados de autenticação</h2>
      <div>
        {inputAuth &&
          inputAuth.map((prop) => <InputComponent inputProp={prop} />)}
      </div>
      <hr />
      <h2>Status da conta</h2>
      <div className="accountStatus">
        {inputStatus &&
          inputStatus.map((prop) => <InputComponent inputProp={prop} />)}
      </div>

      <UserTools user={user}/>
      <UserReferrals />

      <div className="formButton">
        <button type="button" onClick={() => history.push("/users")}>
          Cancelar
        </button>
        <button disabled={formik.isSubmitting} type="submit">
          {formik.isSubmitting ? <CircularProgress size={14}  style={{margin: 0, padding: 0}}/> : 'Enviar'}
        </button>
      </div>
    </UserFormComponent>
  );
}
