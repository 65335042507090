import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ContextAplication } from '../../../context';
import { DefaultTemplate } from '../../../components/templates/defaultTemplates';

import { Container } from './style';
import { UserAdminForm } from '../../../components/molecules/userAdminForm';

export const UserAdminUpdate = () => {
  const { handleGet } = useContext(ContextAplication);

  const [user, setUser] = useState(null);

  const { id } = useParams();

  async function handleGetUser() {
    const response = await handleGet(`/manager-users/${id}`);

    setUser(response);
  }

  useEffect(() => {
    if (id) {
      handleGetUser();
    }
  }, []);

  return (
    <DefaultTemplate>
      <Container>
        <header>
          <h1>Atualizar usuário</h1>
        </header>

        <body>{!!user && <UserAdminForm user={user} />}</body>
      </Container>
    </DefaultTemplate>
  );
};
