import styled from 'styled-components';

export const AppSettingsContainer = styled.div`
  width: 100%;
  height: calc(100vh - 6rem);
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--white);
  background-color: var(--gray-700);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-900);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-700);
  }

  .header {
    width: 100%;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .container__title{
    font-size: 1.5rem;
  }

  .platformSelector__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;

    button {
      padding: 1rem;
      border-radius: 10px 10px 0 0;
      border: none;
      background-color: var(--gray-500);
      color: white;
      font-weight: 700;
      border: 1px solid var(--purple-500);
      border-bottom: none;
      z-index: 1;
      transform: translateY(1px);
      outline: none;

      &.unactive {
        filter: brightness(0.5);
        z-index: unset;
        border: none;
        transform: none;
      }
    }
  }

`
export const SelectComponentStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'var(--gray-700)',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    marginTop: '5px',
    transition: 'all .2s linear',

    ':hover': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'var(--gray-900)',
      cursor: 'pointer'
    },

    ':focus': {
      border: 'none',
      boxShadow: 'none',
    },

    ':active': {
      border: 'none',
      boxShadow: 'none',
    },

    ':focus-visible': {
      border: 'none',
      boxShadow: 'none',
    },

    ':focus-within': {
      border: 'none',
      boxShadow: 'none',
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: 'white',
      color: 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',
      borderRadius: '0',

      ':active': {
        ...styles[':active'],
        backgroundColor: 'var(--gray-100)',
      },

      ':hover': {
        ...styles[':hover'],
        backgroundColor: 'var(--gray-100)',
      },
    };
  },
  placeholder: (styles) => ({ ...styles, color: 'var(--gray-100)' }),
  singleValue: (styles, { data }) => ({ ...styles, color: 'white' }),
  container: (styles) => ({...styles, width: '100%'}),
  input: (styles) => ({...styles, color: 'white'}),
};