import styled from 'styled-components';

export const BannersContainer = styled.div`
  width: 100%;
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 2rem;
  background-color: var(--gray-700);
  overflow-y: scroll;
  &::-webkit-scrollbar {
      width: 8px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: var(--gray-900);
      border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
      background-color: var(--gray-700);
  }
 
  header {
    max-width: 50rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2rem;

    h1 {
      font-size: 2rem;
      color: var(--white);
      margin-bottom: 0.5rem;
    }

    .header-button {
      border: none;
      width: 5rem;
      background-color: var(--purple-500);
      border-radius: 5px;
      margin-bottom: 0.5rem;
      color: var(--white);
    }


    div {
      display: flex;
      justify-content: space-between;

      input {
        background-color: unset;
        border: 1px solid var(--gray-500);
        border-radius: 5px;
        padding: 1rem;
        color: var(--white);
        width: 100%;

        &:hover {
          border-color: var(--gray-300);
        }

        &:focus {
          outline: none;
          border-color: var(--gray-100);
        }

        &:nth-child(2) {
          margin: 0 10px;
        }
      }
    }
  }

  body {
    max-width: 50rem;
    width: 100%;
  }
`
