import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--gray-500);
  color: var(--white);
  padding: 1.2rem;
  border-radius: 5px;

  table {
    width: 100%;
    
    tr {
      height: 3rem;
      
      border-bottom: 1px solid var(--gray-300);
      vertical-align: middle;
      
      th {
        font-size: 1.2rem;
      }

      th, td {
        vertical-align: middle;
        text-align: left;
        &:last-child {
          text-align: end;
        }
      }
    }

    .showUser__button {
      background-color: unset;
      border: none;
      color: var(--green-400);
      font-size: 1.2rem;
      &:hover {
        filter: brightness(0.8);
      }
    }

    span {
      display: flex;
      padding: 2rem 0;
    }
  }
`