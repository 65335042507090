import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContextAplication } from '../../../context';
import { DefaultTemplate } from '../../../components/templates/defaultTemplates';

import { Container } from './style';
import { EmailsForm } from '../../../components/molecules/emailsForm';

export const EmailsUpdate = () => {
  const { handleGet } = useContext(ContextAplication);

  const [email, setEmail] = useState(null);

  const { id } = useParams();

  async function handleGetEmail() {
    const response = await handleGet(`emails/${id}`);

    setEmail(response);
  }

  useEffect(() => {
    if (id) {
      handleGetEmail();
    }
  }, []);

  return (
    <DefaultTemplate>
      <Container>
        <header>
          <h1>Atualizar e-mail</h1>
        </header>

        <body>{email && <EmailsForm email={email}/>}</body>
      </Container>
    </DefaultTemplate>
  );
};
