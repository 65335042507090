import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 6rem);
  padding: 3rem 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  color: var(--white);
  background-color: var(--gray-700);

  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-900);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-700);
  }

  .header {
    width: 100%;
    
    margin-bottom: 2rem;
    
    div {
      
      display: flex;
      justify-content: space-between;
      min-height: 3rem;
      margin-bottom: 0.5rem;
      
      h2 {
        font-size: 2rem;
      }
      
      button {
        background-color: var(--purple-500);
        border-radius: 5px;
        
        border: none;
        outline: none;
        
        cursor: pointer;
        color: var(--white);
        
        transition: opacity 0.2s;
        
        &:hover {
          opacity: 0.8;
        }
      }
    }

    input {
      background-color: unset;
      border: 1px solid var(--gray-500);
      border-radius: 5px;
      padding: 1rem;
      color: var(--white);
      width: calc(100% - 2rem); // tamanho maximo do container menos o padding direito
  
      &:hover {
        border-color: var(--gray-300);
      }
  
      &:focus {
        outline: none;
        border-color: var(--gray-100);
      }
    }
  }
 
`;
