import { ref, uploadBytes } from 'firebase/storage';
import { toast } from 'react-toastify';
import { useStorage } from 'reactfire';

import { useState } from 'react';
import { useEffect } from 'react';

export function CoverImageInput({ item, onSaveImage }) {
  const [coverImage, setCoverImage] = useState(
    process.env.REACT_APP_FIREBASE_URL +
    'homeSpecialties%2F' +
    item.cover +
    '.png?alt=media'
  )

  useEffect(() => {
    setCoverImage(process.env.REACT_APP_FIREBASE_URL +
      'homeSpecialties%2F' +
      item.cover +
      '.png?alt=media')
  }, [item])

  const storage = useStorage();

  
  async function handleCoverImageInput(cover, item, e) {
    if(!cover) {
      return;
    }
    const imageName = `${item._id}${new Date().getTime()}`
    const newRef = ref(storage, `homeSpecialties/${imageName}.png`);
    setCoverImage(null)
    try {
      await uploadBytes(newRef, cover);

      onSaveImage(item, imageName)

      setCoverImage(process.env.REACT_APP_FIREBASE_URL +
        'homeSpecialties%2F' +
        imageName +
        '.png?alt=media')
      
      toast.success('Imagem salva com sucesso!');
    } catch (error) {
      toast.error(error?.response.data.message);
    }
  }

  return (
    <label className="coverImage__image">
          <img
            alt="especialidade capa"
            src={
              `${coverImage}`
            }
            onError={(e) => {
              e.target.onerror = null;
              // e.target.alt = 'ADD'
            }}
          />
          <input
            type="file"
            className="input__coverImage"
            accept=".png"
            onChange={(e) => handleCoverImageInput(e.target.files[0], item, e)}
          />
    </label>
  );
}
