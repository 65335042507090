import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--gray-500);
  color: white;
  padding: 1.2rem;
  border-radius: 5px;
  width: auto;

  table {
    width: 100%;
    
    tr {
      height: 3rem;

      border-bottom: 1px solid var(--gray-300);
      vertical-align: middle;

      th {
        font-size: 1.2rem;
      }

      th, td {
        vertical-align: middle;
        text-align: left;
        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .edit__button {
    background-color: unset;
    border: none;
    height: 2rem;
    width: 2rem;
    color: var(--purple-900);
    font-size: 1.5rem;
  }

  .delete__button {
    background-color: unset;
    border: none;
    height: 2rem;
    width: 2rem;
    color: var(--red-300);
    font-size: 1.5rem;
    margin-left: 1rem;
  }
`;
