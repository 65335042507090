import styled from "styled-components";

export const CarouselContainer = styled.div`
  width: 100%;
  max-width: 50rem;

  .bannerCarousel__filter {
    margin: 1rem 0;
  }

  .platformSelector__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;

    button {
      padding: 1rem;
      border-radius: 10px 10px 0 0;
      border: none;
      background-color: var(--gray-500);
      color: white;
      font-weight: 700;
      border: 1px solid var(--purple-500);
      border-bottom: none;
      z-index: 1;
      transform: translateY(1px);
      outline: none;

      &.unactive {
        filter: brightness(0.5);
        z-index: unset;
        border: none;
        transform: none;
      }
    }
  }

  .filter__title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: var(--white);
  }

  .filter__input {
    background-color: transparent;
    border: 1px solid var(--gray-500);
    border-radius: 5px;
    color: var(--white);
    width: calc(100% - 2rem);
    &:focus {
      outline: none;
    }
  }

  .filterAdd{
    display: flex;
    align-items: center;
  }

  .filter__add{
    color: var(--green-400);
    font-size: 2rem;
    display: flex;
    cursor: pointer;
  }

  .bannerCarousel__table {
    padding: 1.2rem 1.2rem 0;
    background-color: var(--gray-500);
    border: 1px solid var(--purple-500);
    border-radius: 0 0 5px 5px;
    color: var(--white);
    table {
      width: 100%;
      thead {
        text-align: left;
        font-size: 1.2rem;
        tr {
          border-bottom: 1px solid var(--gray-300);
        }
        th {
          padding: 1rem 0;
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid var(--gray-300);
        }
        td {
          padding: 1rem 0;
        }

        .table__positionChangers{
          font-size: 1.3rem;
          width: 10rem;
        }

        .up, .down{
          cursor: pointer;
          color: var(--purple-500);
          background-color: transparent;
          font-size: 1.4rem;
          border: none;
          border-radius: 50%;
          &:disabled{
            filter: opacity(.2);
            cursor: not-allowed;
          }
        }

        .table__deleteButton {
          background-color: transparent;
          border: none;
          height: 2rem;
          width: 2rem;
          color: var(--red-300);
          font-size: 1.5rem;
          margin-left: 1rem;
          cursor: pointer;
        }

        .table__buttonRow {
          border: none;
        }

        .table__buttonColumn {
          text-align: right;
        }

        .table__saveButton{
          background-color: var(--purple-500);
          border: none;
          border-radius: 5px;
          font-size: 1rem;
          padding: 0.5rem 1rem;
          color: var(--white);
          cursor: pointer;
        }
      }
    }
  }
`;
export const SelectComponentStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "var(--gray-700)",
    color: "white",
    border: "1px solid var(--gray-300)",
    borderRadius: "3px",
    marginTop: "5px",
    transition: "all .2s linear",

    ":hover": {
      border: "none",
      boxShadow: "none",
      backgroundColor: "var(--gray-900)",
      cursor: "pointer",
    },

    ":focus": {
      border: "none",
      boxShadow: "none",
    },

    ":active": {
      border: "none",
      boxShadow: "none",
    },

    ":focus-visible": {
      border: "none",
      boxShadow: "none",
    },

    ":focus-within": {
      border: "none",
      boxShadow: "none",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: "black",
      cursor: isDisabled ? "not-allowed" : "default",
      borderRadius: "0",

      ":active": {
        ...styles[":active"],
        backgroundColor: "var(--gray-100)",
      },

      ":hover": {
        ...styles[":hover"],
        backgroundColor: "var(--gray-100)",
      },
    };
  },
  placeholder: (styles) => ({ ...styles, color: "var(--gray-100)" }),
  singleValue: (styles, { data }) => ({ ...styles, color: "white" }),
  container: (styles) => ({ ...styles, width: "100%" }),
  input: (styles) => ({ ...styles, color: "white" }),
};