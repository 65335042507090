import { useState } from 'react';
import { Link } from 'react-router-dom';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';

import { TiChevronRight } from 'react-icons/ti';

import { Container, ListItemComponent } from './style';

export default function SideBar({ selected, sideBarOptions, logo }) {
  const [open, setOpen] = useState({});

  function handleClick(path) {
    setOpen({ ...open, [path]: !open[path] });
  }

  return (
    <Container>
      <header>
        <img src={logo} alt="Imagem logo BlackBook" />
        <h5>BlackBook</h5>
      </header>
      <Divider />
      <List>
        {sideBarOptions.map((option) =>
          option.hasChildren ? (
            <>
              <ListItemComponent
                isOpen={open[option.name]}
                button
                onClick={() => handleClick(option.name)}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
                <span>{option.name}</span>
                <TiChevronRight className="chevron"/>
              </ListItemComponent>

              <Collapse in={open[option.name]} timeout="auto" unmountOnExit>
                <List component="div" >
                  {option.childrens &&
                    option.childrens.map((children) => (
                      <ListItem
                        button
                        selected={children.href === selected}
                        component={Link}
                        to={children.href}
                      >
                        <ListItemIcon>{children.icon}</ListItemIcon>
                        <span>{children.name}</span>
                      </ListItem>
                    ))}
                </List>
              </Collapse>
            </>
          ) : (
            <ListItem
              button
              selected={option.href === selected}
              component={Link}
              to={option.href}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <span>{option.name}</span>
            </ListItem>
          )
        )}
      </List>
    </Container>
  );
}
