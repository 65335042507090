import React, { useContext, useState } from 'react';
import { UsersTable } from '../../components/molecules/usersTable';
import { DefaultTemplate } from '../../components/templates/defaultTemplates';
import { Container } from './style';

import { ContextAplication } from '../../context';

export const Users = () => {
  const { handleGet } = useContext(ContextAplication);
  
  const [usersList, setUsersList] = useState(null);

  let timeout = null;

  async function handleGetUsers(path, param) {
    if(path === 'users/auth') {
      const emailsResponse = await handleGet(`auths/email/${param}`);

      let emailsIds = [] 
      emailsResponse.map(email =>  emailsIds.push(email._id))

      param = JSON.stringify(emailsIds);
    }
    const response = await handleGet(`${path}/${param}`)
    setUsersList(response)
  }

  function debounceInput(field, param) {
    if(param.length < 3) { return }

    clearTimeout(timeout);
    timeout = setTimeout(async function () {
      setUsersList([]);
      return handleGetUsers(field, param);
    }, 800);
  }

  return (

    <DefaultTemplate>
      <Container>
        <header>
          <h1>Usuários</h1>
          <div>
            <input type='text' placeholder='Busque pelo nome' onChange={(e) => debounceInput('users/name', e.target.value)}/>
            <input type='text' placeholder='Busque pelo e-mail' onChange={(e) => debounceInput('users/auth', e.target.value)}/>
            <input type='text' placeholder='Busque pelo CPF/CNPJ' onChange={(e) => debounceInput('users/document', e.target.value)}/>
          </div>
        </header>

        <body>
          <UsersTable
          users={usersList}
          />
        </body>
      </Container>
    </DefaultTemplate>
  )
};
