import { InputLabel } from './style'

export function InputComponent( {inputProp} ) {
  return (
      <InputLabel>
        { inputProp.label }
        <input {...inputProp} />
      </InputLabel>
  )
}
