import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import api from '../../services/axios';
import { handleLogin } from '../../services/auth';
import { InputComponent } from '../../components/atoms/inputComponent';
// import logo from '../../assets/images/logo-white.svg';

import { Container } from './style';

export function Login() {
  const history = useHistory();

  // const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    onSubmit: async (values) => {
      try {
        const response = await api.post('login', values);

        handleLogin(response.data);
        
        history.push('/');
      } catch (error) {
        toast.error('E-mail/senha inválido(a)!')
      }
    }
  })

  return (
    <Container>
      <div className="box">
        <div className="logo-box">
          {/* <img src={logo} alt="Logo BlackBook" /> */}
        </div>

        <form onSubmit={formik.handleSubmit} className="input-box">
          <InputComponent inputProp={{
            label: 'E-mail:',
            id: 'email',
            type: 'email',
            name: 'email',
            onChange: formik.handleChange,
            value: formik.values?.email,
          }}
          />
          <InputComponent inputProp={{
            label: 'Senha:',
            id: 'password',
            type: 'password',
            name: 'password',
            onChange: formik.handleChange,
            value: formik.values?.password,
          }}
          />
          <button type='submit'>Login</button>
        </form>
      </div>
    </Container>
  )
}
