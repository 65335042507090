import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContextAplication } from '../../../context';
import { DefaultTemplate } from '../../../components/templates/defaultTemplates';

import { NotificationsForm } from '../../../components/molecules/notificationsForm';

import { Container } from './style';

export const NotificationsUpdate = () => {
  const { handleGet } = useContext(ContextAplication);

  const [notification, setNotification] = useState();

  const { id } = useParams();

  async function handleGetNotification() {
    const response = await handleGet(`notifications/${id}`);

    setNotification(response);
  }

  useEffect(() => {
    if (id) {
      handleGetNotification();
    }
  }, []);

  return (
    <DefaultTemplate>
      <Container>
        <header>
          <h1>Atualizar notificação</h1>
        </header>

        <body>{notification && <NotificationsForm notification={notification}/>}</body>
      </Container>
    </DefaultTemplate>
  );
};
