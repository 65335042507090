import { useContext } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import { InputComponent } from '../../atoms/inputComponent';
import { ContextAplication } from '../../../context';

import { UserFormComponent } from './style';

export function ChangelogForm({ changelog }) {
  const { handleUpdate } = useContext(ContextAplication);

  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      change_log: changelog?.change_log || '',
    },

    onSubmit: async (values) => {
      try {
        const response = await handleUpdate(`version/${changelog._id}`, values);

        history.push('/changelog');
        return response;
      } catch (error) {
        console.error(error);
      }
    },
  });
  


  return (
    <UserFormComponent onSubmit={formik.handleSubmit}>
      <div>
        <h2 style={{paddingLeft: "1rem"}}>Versão: {changelog.version}</h2>
        <h2 style={{paddingLeft: "1rem"}}>Sub-versão: {changelog.sub_version}</h2>

        <InputComponent inputProp={{
          label: 'Descrição da versão',
          id: 'change_log',
          name: 'change_log',
          type: 'text',
          onChange: formik.handleChange,
          value: formik.values.change_log
        }} />


      </div>
      <hr />

      <div className="formButton">
        <button type="button" onClick={() => history.push('/changelog')}>
          Cancelar
        </button>
        <button disabled={formik.isSubmitting} type="submit">
          {formik.isSubmitting ? (
            <CircularProgress size={14} style={{ margin: 0, padding: 0 }} />
          ) : (
            'Enviar'
          )}
        </button>
      </div>
    </UserFormComponent>
  );
}
