import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ContextAplication } from '../../context';
import { DefaultTemplate } from '../../components/templates/defaultTemplates';

import { Container } from './style';
import { NotificationsTable } from '../../components/molecules/notificationsTable';

export function Notifications() {
  const history = useHistory();

  const { handleGet } = useContext(ContextAplication);

  const [input, setInput] = useState('');

  const [notifications, setNotifications] = useState(null);

  async function handleGetNotifications() {
    const response = await handleGet('notifications');
    setNotifications(response);
  }

  const filteredNotifications = (data) => {
    if (input !== '') {
      const result = data?.filter(({ title }) =>
        title.toLowerCase().includes(input.toLowerCase())
      );

      return result;
    }
    return data;
  };

  useEffect(() => {
    handleGetNotifications();
  }, []);

  return (
    <DefaultTemplate>
      <Container>
        <div className="header">
          <div>
            <h2>Painel de controle de notificações</h2>
            <button
              type="button"
              onClick={() => history.push('/notifications/create')}
            >
              Cadastrar nova notificação
            </button>
          </div>
          <input
            type="text"
            placeholder="Digite sua busca"
            onChange={(e) => setInput(e.target.value)}
          />
        </div>

        <NotificationsTable notifications={filteredNotifications(notifications)} />
      </Container>
    </DefaultTemplate>
  );
}
