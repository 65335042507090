import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: var(--gray-900);
  display: flex;
  justify-content: center;
  overflow: hidden;

  .box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-height: calc(100vh - 10rem);
    margin-top: 5rem;

    .logo-box {
      img {
        width: 300px;
      }
    }

    .input-box {
      background-color: var(--gray-700);
      padding: 1.5rem;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      
      label {
        padding: 0;
        color: var(--white);
      }
      
      input {
        transition: 0.2s;
        &:hover {
          background-color: var(--gray-500);
        }
      }
    }

    button {
      border-radius: 5px;
      border: none;
      background-color: var(--purple-500);
      color: var(--white);
      padding: 0.5rem;
      font-weight: bold;
      margin-top: 1rem;
      transition: linear 0.2s;
      &:hover {
        background-color: #6c5ecc;
      }
    }
  }
`;