/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import api from '../../../services/axios';
import { Pagination } from '../../atoms/pagination';

import { Container } from './style';

export function BannersTable({ banners, handleDeleteBanner }) {
  const [page, setPage] = useState(1);
  const [bannersArray, setBannersAreasArray] = useState(null)

  const history = useHistory();

  function handleActuationAreasArray() {
    setBannersAreasArray(banners?.slice((page - 1) * 10, page * 10))
  }

  useEffect(() => {
    if (banners) {
      handleActuationAreasArray()
    }
  }, [banners, page])

  return (
    <Container>
      <table>
        <tr>
          <th>Título</th>
          <th>Ativo</th>
          <th></th>
        </tr>

        {bannersArray && bannersArray.map((banner) => (
          <tr>
            <td>{banner?.title}</td>
            <td>{banner?.active}</td>
            <td>
              <button
                className="edit__button"
                onClick={() => history.push(`/banners/update/${banner._id}`)}
              >
                <AiFillEdit />
              </button>
              <button
                className="delete__button"
                onClick={() => handleDeleteBanner(banner)}
              >
                <MdDelete />
              </button>
            </td>
          </tr>
        ))}
      </table>

      {banners?.length > 10 &&
        <Pagination currentPage={page} totalCountOfRegisters={banners.length} onPageChange={(e) => setPage(e)} />
      }
    </Container>
  )
}
