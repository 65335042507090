import styled from 'styled-components';
 
export const UserFormComponent = styled.form`
  color: var(--white);
  padding: 2rem;

  .input-select {
    margin-top: 15px;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .sentFilters {
    table {
      width: 100%;
      th {
        text-align: left;
        font-size: 1.2rem;
      }
      th:after {
        color: transparent;
        content: " - ";
        display: block;
        line-height: 1rem;
      }
      tr{
        border: 2px solid var(--gray-100);
      }
      td{
        line-height: 2rem;
      }
    }
  }

  hr {
    margin-bottom: 1rem;
  }

  .select-container {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        background-color: unset;
        color: #32cd30;
      }
    }
    div {
      display: flex;
      height: 3rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid var(--gray-300);
      padding: 0 1rem;

      select {
        color: var(--white);
        width: 100%;
        background-color: var(--gray-500);
        margin-right: 1rem;

        border-radius: 5px;
        border: none;
        outline: none;
      }

      button {
        background-color: unset;
        border: none;
        outline: none;
        color: var(--red-500);
        font-size: 1.3rem;
      }
    }
  }

  .formButton {
    display: flex;
    justify-content: space-between;

    button {
      border: none;
      border-radius: 20px;
      padding: 0.5rem;
      margin-top: 2rem;
      margin-right: 1rem;
      margin-left: 1rem;
      color: white;
      font-weight: 700;
      font-size: 1rem;
      background-color: var(--green-400);
      width: 100%;
      max-width: 15rem;
      &:first-of-type {
        background-color: var(--red-500);
      }
    }
  }
`;

export const FilterContainer = styled.div`
  h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  h2 {
    margin: 0;
  }

  .filters {
    &:hover {
      cursor: pointer;
    }

    background-color: var(--gray-700);

    border-radius: 5px;
    padding: 0.5rem;
    padding-bottom: 0.1rem;
    h3 {
      height: 1rem;
      text-align: center;
      padding: 1rem;
      padding-top: 0.5rem;
      font-size: 1.3rem;
    }

    div {
      display: ${({ whoIsOpen }) => (whoIsOpen === "filters" ? "" : "none")};
      background-color: var(--gray-500);
      padding: 0 0.5rem;

      border-radius: 5px;
    }
  }

  .users {
    &:hover {
      cursor: pointer;
    }

    background-color: var(--gray-700);

    margin-top: 1rem;
    border-radius: 5px;
    padding: 0.5rem;
    padding-bottom: 0.5rem;
    h3 {
      height: 1rem;
      text-align: center;
      padding: 1rem;
      padding-top: 0.5rem;
      font-size: 1.3rem;
    }
    .userList {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      .userList__content {
        margin: 4px 4px 0 0;
        display: flex;
        flex-wrap: nowrap;
        background-color: var(--gray-700);
        padding: 0.5rem;
        border-radius: 10px;
        p {
          margin-right: 1em;
        }
        button {
          background-color: unset;
          border: none;
          outline: none;
          color: var(--red-500);
          font-size: 1rem;
        }
      }
    }
    div {
      display: ${({ whoIsOpen }) => (whoIsOpen === "users" ? "" : "none")};
      background-color: var(--gray-500);
      padding: 0 0.5rem;

      border-radius: 5px;
      margin-top: 30px;

      .header {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
          margin: 1rem 0;
        }

        div {
          display: flex;
          width: 100%;
          justify-content: space-between;
          input {
            width: 100%;
            margin: 0 0.5rem;
            padding: 0.5rem;

            border: none;
            border-radius: 5px;
            background-color: var(--gray-100);
            color: var(--white);
            &:focus {
              outline: none;
            }
            &::placeholder {
              color: white;
            }
          }
        }
      }

      table {
        width: 100%;
        hyphens: auto;

        thead {
          margin-bottom: 1rem;
          text-align: left;
          th {
          }
        }
        tbody {
          &:before {
            color: transparent;
            content: " - ";
            display: block;
            line-height: 1rem;
          }
          tr {
            height: 1.5rem;
            td {
            }
          }
        }
        button {
          border: none;
          background-color: unset;
          color: #32cd30;
        }
      }
    }
  }
`;