import { DefaultTemplate } from '../../../components/templates/defaultTemplates';

import { Container } from './style';
import { UserAdminForm } from '../../../components/molecules/userAdminForm';

export const UserAdminCreate = () => {
  return (

    <DefaultTemplate>
      <Container>
        <header>
          <h1>Criar usuário</h1>
          
        </header>

        <body>
          <UserAdminForm isCreate={true} user={null}/>
        </body>
      </Container>
    </DefaultTemplate>
  )
};
