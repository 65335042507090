import { useState, useEffect } from "react";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { RiCloseFill } from 'react-icons/ri';
import api from "../../../services/axios";
import { ModalLinkContainer, SelectComponentStyles } from "./style";

export function ModalLink({ banner, setOpenModalLink, setBannerLink }) {

  const [isForRoutine, setIsForRoutine] = useState(true);
  const [isExternalLink, setIsExternalLink] = useState(Boolean(banner?.banner_external_link));
  const [externalLink, setExternalLink] = useState(banner.banner_external_link);
  const [linkManagerObject, setLinkManagerObject] = useState(null);
  const [routines, setRoutines] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [cards, setCards] = useState([]);
  const [therapeuticGroup, setTherapeuticGroup] = useState([]);
  const [activePrinciples, setActivePrinciples] = useState([]);
  const [dosages, setDosages] = useState([]);

  const handleByQuery = async (url, setState) => {
    try {
      const response = await api.get(url);

      const newArray = await response.data.map((obj) => {
        return {
          label: obj?.legend ? `${obj.title} - ${obj.legend}` : obj.title,
          value: obj._id,
          object: obj,
        };
      });
      if (setState) {
        setState(newArray);
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  async function handleGetLink() {
    try {
      if (banner?.banner_external_link || !banner?.banner_link_id) {
        return;
      }

      const { data } = await api.get(`/link-banner/${banner._id}`);

      const parsedLinkObject = 
      {
        therapeutic_group_id: data?.therapeutic_group_id
          ? {
            value: data.therapeutic_group_id._id,
            label: data.therapeutic_group_id.title,
          }
          : null,
        active_principle_id: data?.active_principle_id
          ? {
            value: data.active_principle_id._id,
            label: data.active_principle_id.title,
          }
          : null,
        dosage_id: data?.dosage_id
          ? {
            value: data.dosage_id._id,
            label: `${data.dosage_id.category_type_id.title} - ${data.dosage_id.recommendation}`,
          }
          : null,
        routine_id: data?.routine_id
          ? {
            value: data.routine_id._id,
            label: `${data.routine_id.title} - ${data.routine_id.legend}`,
            object: data.routine_id,
          }
          : null,
        tab_id: data?.tab_id
          ? {
            value: data.tab_id._id,
            label: data.tab_id.title,
            object: data.tab_id,
          }
          : null,
        card_id: data?.card_id
          ? {
            value: data.card_id._id,
            label: data.card_id.title,
          }
          : null,
        field_origin: data?.field_origin,
        link_id: data?.link_id,
        link_origin_id: data?.link_origin_id,
        link_origin_text: data?.link_origin_text,
        type: data?.type,
        _id: data?._id,
      };

      setIsForRoutine(parsedLinkObject.type === 'R' ? true : false);
      setLinkManagerObject(parsedLinkObject);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleByQuery(
      `therapeuticgroup/query-filter?select[]=title`,
      setTherapeuticGroup
    );
    handleByQuery(
      `routine/collection-and-query/r_routine?select[]=title&select[]=routine_tabs_id&select[]=legend`,
      setRoutines
    );
    handleGetLink();
  }, []);

  const handleFormChange = async (field, value) => {
    const fieldOptions = {
      therapeutic_group_id: async function () {
        let newArray = [];
        if (value) {
          const response = await api.get(
            `activeprinciple/query-filter?select[]=title&query[therapeutic_group_id][_id]=${value.value}&query[hidden]=false`
          );

          newArray = await response.data.map((obj) => {
            return {
              label: obj.title,
              value: obj._id,
            };
          });
        }
        setActivePrinciples(newArray);
        setDosages([]);
        setLinkManagerObject({
          ...linkManagerObject,
          therapeutic_group_id: value,
          active_principle_id: null,
          dosage_id: null,
        });
      },

      active_principle_id: async function () {
        let newArray = [];
        if (value) {
          const response = await api.get(
            `dosage/by-active-principle/${value.value}`
          );
          newArray = await response.data.map((obj) => {
            return {
              label: `${obj.category_type_id.title} - ${obj.recommendation}`,
              value: obj._id,
            };
          });
        }
        setDosages(newArray);
        setLinkManagerObject({
          ...linkManagerObject,
          active_principle_id: value,
          dosage_id: null,
        });
      },

      dosage_id: function () {
        setLinkManagerObject({ ...linkManagerObject, dosage_id: value });
      },

      routine_id: async function () {
        let newArray = [];
        if (value?.object.routine_tabs_id.length) {
          const query = value.object.routine_tabs_id.map((id) => `query[_id][]=${id}`).join('&');
          const response = await api.get(
            `routine/collection-and-query/r_routine_tabs?select[]=tab_id&select[]=routine_tabs_cards_id&${query}`
          );
          newArray = await response.data.map((obj) => {
            return {
              label: obj.tab_id.title,
              value: obj.tab_id._id,
              object: obj,
            };
          });
        }
        setTabs(newArray);
        setCards([]);
        setLinkManagerObject({
          ...linkManagerObject,
          routine_id: value,
          tab_id: null,
          card_id: null,
        });
      },

      tab_id: async function () {
        let newArray = [];
        if (value?.object.routine_tabs_cards_id?.length) {
          const query = value.object.routine_tabs_cards_id.map((id) => `query[_id][]=${id}`).join('&');
          const response = await api.get(
            `routine/collection-and-query/r_routine_tab_cards?select[]=card_id&${query}`
          );
          newArray = await response.data.map((obj) => {
            return {
              label: obj.card_id.title,
              value: obj.card_id._id,
            };
          });
        }
        setCards(newArray);
        setLinkManagerObject({
          ...linkManagerObject,
          tab_id: value,
          card_id: null,
        });
      },

      card_id: function () {
        setLinkManagerObject({ ...linkManagerObject, card_id: value });
      },
    };

    await fieldOptions[field]();
  };

  async function handleSubmitLink() {
    if(isExternalLink) {
      await api.put('/update-banner', {_id: banner._id, banner_external_link: externalLink, banner_link_id: null})
      setBannerLink({...banner, banner_external_link: externalLink, banner_link_id: null })
      setOpenModalLink(false)
      return;
    }
    const parsedLinkManager = {
      type: isForRoutine ? 'R' : 'D',
      routine_id:
        isForRoutine && linkManagerObject?.routine_id
          ? linkManagerObject.routine_id.value
          : null,
      tab_id:
        isForRoutine && linkManagerObject?.tab_id
          ? linkManagerObject.tab_id.value
          : null,
      card_id:
        isForRoutine && linkManagerObject?.card_id
          ? linkManagerObject.card_id.value
          : null,
      therapeutic_group_id:
        !isForRoutine && linkManagerObject?.therapeutic_group_id
          ? linkManagerObject.therapeutic_group_id.value
          : null,
      active_principle_id:
        !isForRoutine && linkManagerObject?.active_principle_id
          ? linkManagerObject.active_principle_id.value
          : null,
      dosage_id:
        !isForRoutine && linkManagerObject?.dosage_id
          ? linkManagerObject.dosage_id.value
          : null,
      link_origin_id: banner._id,
    };

    try {
      let response = null;

      if (linkManagerObject?.link_id) {
        response = await api.put(
          `/link-manager/${linkManagerObject._id}`,
          parsedLinkManager
        );
      } else {
        response = await api.post(
          `/create-link/banner`,
          parsedLinkManager
        );
      }
      await api.put('/update-banner', {_id: banner._id, banner_link_id: response.data.link_id, banner_external_link: null})
      setBannerLink({...banner, banner_link_id: response.data.link_id, banner_external_link: null})
      setOpenModalLink(false)
    } catch (error) {
      toast.error('Ocorreu um erro!', {
        theme: 'colored',
        closeOnClick: true,
      });
      console.log(error);
    }
  }

  return (
    <ModalLinkContainer>
      <div className="link__modal">
        <div className="modal__header">
          <h3>
            { linkManagerObject?.link_id || externalLink ? 'Editar Link' : 'Criar Link'}
          </h3>
          <button onClick={() => setOpenModalLink(false)}>
            <RiCloseFill />
          </button>
        </div>
        <div className="modal__body">
          <div className="modalType__selector">
            <button
              className={isForRoutine && !isExternalLink && 'active'}
              onClick={() => {
                setIsForRoutine(true);
                setIsExternalLink(false);
                setExternalLink(null);
              }}
            >
              Rotina
            </button>
            <button
              className={!isForRoutine && !isExternalLink && 'active'}
              onClick={() => {
                setIsForRoutine(false);
                setIsExternalLink(false);
                setExternalLink(null)
              }}
            >
              Droga
            </button>
            <button
              className={isExternalLink && 'active'}
              onClick={() => {
                setIsExternalLink(true);
              }}
            >
              Link externo
            </button>
          </div>
          {isExternalLink ? (

            <input
              className="externalLink__input"
              onChange={(e) => setExternalLink(e.target.value)}
              value={externalLink}
              type="text"
              placeholder="Digite o link externo"
            />

          ) : isForRoutine ? (
            <div className="form__container">
              <Select
                placeholder="Selecione uma rotina..."
                value={linkManagerObject?.routine_id || null}
                name="routine"
                styles={SelectComponentStyles}
                options={routines}
                onChange={(e) => handleFormChange('routine_id', e)}
                isClearable={true}
              />
              <Select
                placeholder="Selecione uma aba..."
                value={linkManagerObject?.tab_id || null}
                name="tab"
                styles={SelectComponentStyles}
                options={tabs}
                onChange={(e) => handleFormChange('tab_id', e)}
                isClearable={true}
              />
              <Select
                placeholder="Selecione um card..."
                value={linkManagerObject?.card_id || null}
                name="card"
                styles={SelectComponentStyles}
                options={cards}
                onChange={(e) => handleFormChange('card_id', e)}
                isClearable={true}
              />
            </div>
          ) : (
            <div className="form__container">
              <Select
                placeholder="Selecione um grupo terapêurico..."
                value={linkManagerObject?.therapeutic_group_id || null}
                name="therapeuticGroup"
                styles={SelectComponentStyles}
                options={therapeuticGroup}
                onChange={(e) => handleFormChange('therapeutic_group_id', e)}
                isClearable={true}
              />
              <Select
                placeholder="Selecione um princípio ativo..."
                value={linkManagerObject?.active_principle_id || null}
                name="ActivePrinciple"
                styles={SelectComponentStyles}
                options={activePrinciples}
                onChange={(e) => handleFormChange('active_principle_id', e)}
                isClearable={true}
              />
              <Select
                placeholder="Selecione uma dose..."
                value={linkManagerObject?.dosage_id || null}
                name="dosage"
                styles={SelectComponentStyles}
                options={dosages}
                onChange={(e) => handleFormChange('dosage_id', e)}
                isClearable={true}
              />
            </div>
          )}
        </div>

        <div className="button__container">
          <button className="save__button" onClick={handleSubmitLink}>
            Salvar
          </button>
        </div>
      </div>
    </ModalLinkContainer>
  )
}
