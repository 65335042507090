import styled from 'styled-components';

export const BannerHTMLPreviewContainer = styled.div`
  .inputs__div {
    label {
      display: flex;
      flex-direction: column;

      .input__text {
        font-size: 1rem;
        color: white;
        width: auto;
        margin-bottom: 1rem;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid var(--gray-100);
        &:focus {
          outline: none;
        }
      }
    }

    .input__image {
      margin-top: 0.5rem;
    }

    .dualInput{
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }

    .input__checkbox{
      display: flex;
      flex-direction: row;
      justify-content: baseline;
      align-items: flex-end;
      margin-top: 0.5rem;
      input{
        margin: 0 5px;
      }
    }

    .button__link {
      border: none;
      border-radius: 2px;
      padding: 0.3rem;
      margin-top: 1.5rem;
      background-color: var(--purple-500);
      color: white;
      &:hover {
        filter: brightness(0.9);
      }
      &:disabled {
        display: none;
      }
    }
  }
`
