import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ContextAplication } from '../../context';
import { DefaultTemplate } from '../../components/templates/defaultTemplates';

import { Container } from './style';
import { ChangelogTable } from '../../components/molecules/changelogTable';

export function Changelog() {
  const history = useHistory();

  const { handleGet } = useContext(ContextAplication);

  const [input, setInput] = useState('');

  const [versions, setVersions] = useState(null);

  async function handleGetVersions() {
    const response = await handleGet('version');
    setVersions(response);
  }

  const filteredEmails = (data) => {
    if (input !== '') {
      const result = data?.filter(({ title }) =>
        title.toLowerCase().includes(input.toLowerCase())
      );

      return result;
    }
    return data;
  };

  useEffect(() => {
    handleGetVersions();
  }, []);

  return (
    <DefaultTemplate>
      <Container>
        <div className="header">
          <div>
            <h2>Painel de controle de descrição das atualizações</h2>
          </div>
          <input
            type="text"
            placeholder="Digite sua busca"
            onChange={(e) => setInput(e.target.value)}
          />
        </div>

        <ChangelogTable changelogs={versions} />
      </Container>
    </DefaultTemplate>
  );
}
