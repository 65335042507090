import styled from 'styled-components';

export const CreateActuationAreasContainer = styled.div `
  width: 100%;
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 2rem;
  background-color: var(--gray-700);

  header {
    max-width: 50rem;
    width: 100%;
    margin-bottom: 2rem;

    h1 {
      font-size: 2rem;
      color: var(--white);
      margin-bottom: 0.5rem;
    }
  }

  body {
    max-width: 50rem;
    width: 100%;

    border-radius: 5px;

    background-color: var(--gray-500);

    form {
      color: var(--white);
      padding: 2rem;

      display: flex;
      flex-direction: column;
      button {
        align-self: flex-end;
        width: 5rem;
        font-size: 1rem;

        padding: .5rem;
        margin-right: 1rem;
        
        border: none;
        border-radius: 5px;

        background-color: var(--purple-500);
        color: var(--white);
      }
    }

  }
`;
