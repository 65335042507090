/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useEffect } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import { Pagination } from '../../atoms/pagination';
import { Table } from './style';

export function UsersTable({ users }) {
  const [page, setPage] = useState(1);
  const [userArray, setUserArray] = useState(null);

  function handleUsersArray() {
    setUserArray(users?.slice((page - 1) * 10, page * 10));
  }

  useEffect(() => {
    if (users) {
      handleUsersArray();
    }
  }, [users, page]);

  return (
    <>
      <Table>
        <table>
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>CPF/CNPJ</th>
            <th></th>
          </tr>

          {userArray &&
            userArray.map((user) => (
              <tr>
                <td>{user?.name}</td>
                <td>{user?.auth?.email}</td>
                <td>{user?.document}</td>
                <td>
                  <Link to={`/users/update/${user?._id}`}>
                    <AiFillEdit />
                  </Link>
                </td>
              </tr>
            ))}
        </table>
      </Table>

      {users?.length > 10 && (
        <Pagination
          currentPage={page}
          totalCountOfRegisters={users.length}
          onPageChange={(e) => setPage(e)}
        />
      )}
    </>
  );
}
