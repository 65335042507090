import { useContext } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import parser from 'html-react-parser';

import CircularProgress from '@material-ui/core/CircularProgress';

import { InputComponent } from '../../atoms/inputComponent';
import { ContextAplication } from '../../../context';

import { UserFormComponent } from './style';

export function EmailsTemplatesForm({ emailTemplate = null, isCreate = false }) {
  const { handleCreate, handleUpdate } =
    useContext(ContextAplication);

  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      name: emailTemplate?.name || '',
      subject: emailTemplate?.subject || '',
      html: emailTemplate?.html || '',
    },

    onSubmit: async (values) => {
      try {
        let response = null;

        isCreate
          ? (response = await handleCreate('templates', values))
          : (response = await handleUpdate(
            `templates`,
            values
          ));

        history.push('/emailstemplates');
        return response;
      } catch (error) {
        console.error(error);
      }
    },
  });

  function handleRenderPreview(html) {
    try {
      const parsedHtml = parser(html)
      return parsedHtml;
    } catch (error) {
      return `<h1>Falha ao renderizar o preview</h1>`
    }
  }

  const inputEmailTemplate = [
    {
      id: 'name',
      label: 'Nome do Template',
      type: 'text',
      name: 'name',
      disabled: !isCreate,
      onChange: formik.handleChange,
      value: formik.values.name,
    },
    {
      id: 'subject',
      label: 'Assunto',
      type: 'text',
      name: 'subject',
      onChange: formik.handleChange,
      value: formik.values.subject,
    },
    {
      id: 'html',
      label: 'Conteúdo',
      type: 'textarea',
      name: 'html',
      onChange: formik.handleChange,
      value: formik.values.html,
    },
  ];

  

  function handleFormSubmit(e) {
    e.preventDefault();
    try {
      formik.handleSubmit();
    } catch (error) {
      console.log(error);
      return;
    }
  }

  return (
    <UserFormComponent onSubmit={handleFormSubmit}>
        <div>
          {inputEmailTemplate &&
            inputEmailTemplate.map((prop) => (
              <InputComponent inputProp={prop} />
            ))}
        </div>
        <hr />

       

      <div>
        {formik.values.html && (
          <div className="preview_html">
            <h1>Prévia do conteúdo do e-mail:</h1>
            <div>{handleRenderPreview(formik.values.html)}</div>
          </div>
        )}
      </div>

      <div className="formButton">
        <button type="button" onClick={() => history.push('/emailstemplates')}>
          Cancelar
        </button>
        <button disabled={formik.isSubmitting} type="submit">
          {formik.isSubmitting ? (
            <CircularProgress size={14} style={{ margin: 0, padding: 0 }} />
          ) : (
            'Enviar'
          )}
        </button>
      </div>
    </UserFormComponent>
  );
}
