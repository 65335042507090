import { useState } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { MdAdd } from 'react-icons/md';

import { SpecialtiesContainerStyles, SelectComponentStyles } from './styles';
import api from '../../../services/axios';
import { useEffect } from 'react';
import { AppSettingsList } from '../../../components/molecules/appSettingsList';
import { toast } from 'react-toastify';

const routeDef = {
  list: {
    routine: '/routinespecialties',
    drug: '/drugspecialties',
  },
  selected: {
    routine: '/home-routine',
    drug: '/home-drug',
  },
  create: {
    routine: '/home-create/routine-image-specialties',
    drug: '/home-create/drug-image-specialties',
  },
  delete: {
    routine: '/home-delete/routine-image/',
    drug: '/home-delete/drug-image/',
  },
  save_list: {
    routine: '/home-update/routine-image-specialties',
    drug: '/home-update/drug-image-specialties',
  },
};

export function SpecialtiesContainer({ route }) {
  const [specialtySelected, setSpecialtySelected] = useState('');

  const [specialtiesOptions, setSpecialtiesOptions] = useState([]);

  const [selectedList, setSelectedList] = useState([]);

  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

  async function handleGetSpecialties() {
    try {
      const response = await api.get(routeDef.list?.[route]);

      const newArray = await response.data.map((specialty) => {
        return { label: specialty.title, value: specialty._id };
      });
      setSpecialtiesOptions(newArray);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleGetSelectedItems() {
    try {
      const response = await api.get(routeDef.selected?.[route]);
      setSelectedList(response.data);

      setSpecialtySelected(null);

      setIsAddButtonDisabled(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleAddSpecialty() {
    setIsAddButtonDisabled(true);
    let repeatedItem = selectedList.find(
      (i) => specialtySelected.value === i.specialty
    );

    if (selectedList?.length >= 11 || repeatedItem) {
      toast.error(
        repeatedItem
          ? 'Esse item já existe!'
          : 'Você atingiu o limite de itens que podem ser exibidos na Home!'
      );
      setIsAddButtonDisabled(false);
      return;
    }

    try {
      console.log(selectedList?.length)
      await api.post(routeDef.create?.[route], {
        specialty_id: specialtySelected.value,
        title: specialtySelected.label,
        cover: '',
        position: selectedList?.length,
      });
      await handleGetSelectedItems();
    } catch (error) {
      console.log(error);
    }

    setIsAddButtonDisabled(false);
  }

  async function handleDeleteItem(item) {
    setIsLoading(true);
    try {
      if (window.confirm('Deseja remover esse item da lista?')) {
        await api.delete(`${routeDef.delete?.[route]}${item._id}`);
        await handleGetSelectedItems();
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }


  async function handleSaveSelectedItems() {
    try {
      const response = selectedList.map((specialty) => {
        return { _id: specialty._id, position: specialty.position };
      });
      console.log(routeDef.save_list?.[route], route)
      await api.put(routeDef.save_list?.[route], response);
      toast.success(`Lista de ${(route === 'routine') ? 'ROTINAS' : 'DROGAS'} atualizadas com sucesso!`);
    } catch (error) {
      console.log(error);
    }
  }

  const [isLoading, setIsLoading] = useState(false);

  async function getData() {
    setIsLoading(true);
    await handleGetSpecialties();
    await handleGetSelectedItems();
    setIsLoading(false);
  }

  useEffect(() => {
    getData();
  }, [route]);

  return (
    <SpecialtiesContainerStyles>
      <div className="specialties__container">
        {isLoading ? (
          <span>carregando...</span>
        ) : (
          <>
            <h3 className="container__title">{route}</h3>
            <div className="specialty__list">
              <Select
                placeholder="Digite e selecione uma Especialidade..."
                value={specialtySelected}
                name="specialties"
                styles={SelectComponentStyles}
                options={specialtiesOptions}
                onChange={(e) => setSpecialtySelected(e)}
              />

              <button
                className="add__button"
                disabled={_.isEmpty(specialtySelected) || isAddButtonDisabled}
                onClick={handleAddSpecialty}
              >
                <MdAdd />
              </button>
            </div>
            <AppSettingsList
              handleDeleteItems={handleDeleteItem}
              handleGetSelectedItems={() => handleGetSelectedItems()}
              selectedItems={selectedList}
              setSelectedItems={(e) => setSelectedList(e)}
              requestType={route}
            />

            {/* <button className="save__button" onClick={handleSaveSelectedItems}>
              Salvar
            </button> */}
          </>
        )}
      </div>
    </SpecialtiesContainerStyles>
  );
}
