import styled from 'styled-components';

export const BannerPreviewBanners = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  overflow-x: auto;
  margin-top: 10px;
`;

export const Container = styled.div`
  background-color: transparent;
  max-width: ${(props) => props.dimensions}px;
  min-width: ${(props) => props.dimensions}px;
  height: ${(props)=> {
    if(props.dimensions === 340){
      return "130px"
    } else if (props.dimensions === 450){
      return "200px"
    } else return "130px"
  }};
  overflow: hidden;
  border-radius: 10px;
  margin:10px 0;
  section {
    scroll-snap-align: center;
    width: auto;
    height: 100%;
    z-index: 10;
    position: relative;
    margin-right: 20px;
    a > div {
      margin-right: 0;
      p {
        font-family: 'Open Sans', sans-serif;
        font-size: ${(props) => {
          if(props.dimensions === 340 || props.dimensions === 450){
            return `${props.dimensions === 340? 13 + (2 / 100) * 150 : 13 + (2/100) * 200}px`;
          } else return `${8 + (1.4 / 100) * props.dimensions}px`; 
        }};
        line-height:  ${(props) => {
          if(props.dimensions === 340 || props.dimensions === 450){
            return `${props.dimensions === 340? 15 + (2 / 100) * 150 : 15 + (2/100) * 200}px`;
          } else return `${15 + (1.5 / 100) * props.dimensions}px`; 
        }};
      }
    }
  }

  a {
    pointer-events: none;
  }
`;