import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../services/axios';

import { TiChevronRight } from 'react-icons/ti';
import { UserReferralTable } from '../../molecules/userReferralTable';
import { UserReferredTable } from '../../molecules/userReferredTable';

import { Container } from './style';

export function UserReferrals() {
  const [showReferrals, setShowReferrals] = useState(false);
  const [referralsUsers, setReferralsUsers] = useState(null);
  const [referredUsers, setReferredUsers] = useState(null);

  const { id } = useParams();

  async function handleGetReferralsUsers() {
    try {
      const response = await api.get(`/indications/${id}`)
      setReferralsUsers(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  async function handleGetReferredUsers() {
    try {
      const response = await api.get(`/indicators-user/${id}`)
      setReferredUsers(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetReferredUsers();
    handleGetReferralsUsers();
  }, [])

  return (
    <Container isOpen={showReferrals}>
      <h2 onClick={() => setShowReferrals(!showReferrals)}>
        Indicações
        <TiChevronRight />
      </h2>

      {showReferrals && (
        <>
          <div className="referrals">
            <h3 className="referrals__title">Usuários indicados:</h3>
            <UserReferralTable referralsUsers={referralsUsers} />
          </div>
          <div className="referred">
            <h3 className="referred__title">Indicado por:</h3>
            <UserReferredTable referredUsers={referredUsers} />
          </div>
        </>
      )}
    </Container>
  );
}