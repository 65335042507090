import styled from 'styled-components';

export const AppSettingsListContainer = styled.div`
  background-color: var(--gray-500);
  margin-top: 1rem;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    border-bottom: 1px solid var(--gray-100);
    background-color: unset;

    &:last-child {
      border-bottom: none !important;
    }

    button{
      border: none;
      background: transparent;
      padding: 0;
      cursor: pointer;
      &:disabled{
        cursor: not-allowed;
        filter: grayscale(80%);
      }
    }

    .listItem__orderArrow {
      margin-right: 0.3rem;
      font-size: 1.5rem;
      color:var(--green-400);
      &.up{
        margin-right: 1rem;
      }
    }

    img,
    .coverImage__placeholder {
      min-width: 48px;
      min-height: 48px;
      max-width: 48px;
      max-height: 48px;
      border: none;
      background: transparent;
      font-size: 1.7rem;
      color: var(--purple-500);
      margin: .5rem 1rem .5rem 0;
      cursor: pointer;
      transition: all linear 0.3s;
      &:hover {
        transform: scale(1.05);
      }
    }

    .coverImage__placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h3 {
      text-align: left;
      width: 100%;
    }
  }

  .input__coverImage {
    display: none;
  }

  .delete__button {
    background-color: unset;
    border: none;
    color: var(--red-300);
    font-size: 1.2rem;
    &:hover {
      filter: brightness(0.8);
    }
  }
`;