/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { Pagination } from '../../atoms/pagination';
import { Container } from './style';

export function EmailsTable({ emails }) {
  const [page, setPage] = useState(1);
  const [emailsArray, setEmailsArray] = useState(null)

  function handleEmailsArray() {
    setEmailsArray(emails?.slice((page-1)*10,page*10))
  }

  useEffect(() => {
    if(emails) {
      handleEmailsArray()
    }
  }, [emails, page])

  return (
    <Container>
    <table>
        <tr>
          <th>Nome</th>
          <th>Assunto</th>
          <th></th>
        </tr>

        {emailsArray && emailsArray.map((email) => (
          <tr>
            <td>{email?.alias}</td>
            <td>{email?.subject}</td>
            <td>
              <Link to={`/emails/update/${email?._id}`}>
                <AiFillEdit />
              </Link>
            </td>
          </tr>
        ))}
    </table>

    {emails?.length > 10 && 
      <Pagination currentPage={page} totalCountOfRegisters={emails.length} onPageChange={(e) => setPage(e)}/>
    }
    </Container>
  )
}
