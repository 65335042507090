import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContextAplication } from '../../../context';
import { DefaultTemplate } from '../../../components/templates/defaultTemplates';

import { Container } from './style';
import { ChangelogForm } from '../../../components/molecules/changelogForm';

export const ChangelogUpdate = () => {
  const { handleGet } = useContext(ContextAplication);

  const [changelog, setChangelog] = useState();

  const { id } = useParams();

  async function handleGetTemplateEmail() {
    const response = await handleGet(`version/${id}`);

    setChangelog(response);
  }

  useEffect(() => {
    if (id) {
      handleGetTemplateEmail();
    }
  }, []);

  return (
    <DefaultTemplate>
      <Container>
        <header>
          <h1>Atualizar descrição da atualização</h1>
        </header>

        <body>{changelog && <ChangelogForm changelog={changelog}/>}</body>
      </Container>
    </DefaultTemplate>
  );
};
