import styled from 'styled-components';

export const InputLabel = styled.label`
    display: flex;
    flex-direction: column;
    margin-bottom: .5rem;
    padding: 0 1rem;
    input {
        margin-top: .3rem;
        border: none;
        border-radius: 5px;
        padding: .5rem;
        background-color: var(--gray-300);
        color: white;
        &:focus {
            outline: none;
        }
        &:hover {
            background-color: var(--gray-700);
        }
    }
`;