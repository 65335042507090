import { useHistory } from "react-router-dom";
import { BackButtonContainer } from "./style";

export function BackButton({ route }) {

  const history = useHistory();

  return (
    <BackButtonContainer
      onClick={() => history.push(`${route}`)}
    >
        Voltar
    </BackButtonContainer>
  )
}
