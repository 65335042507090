import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--gray-500);
  color: white;
  padding: 1.2rem;
  border-radius: 5px;
  width: 100%;

  table {
    width: 100%;
    
    tr {
      height: 3rem;
      border-bottom: 1px solid var(--gray-300);
      vertical-align: middle;

      th {
        font-size: 1.2rem;
      }

      th, td {
        vertical-align: middle;
        text-align: left;
      }

      svg {
        font-size: 1.2rem;
        width: 100%;
      }

      a:visited {
        color: #3104ee;
      }
    }
  }
`;
