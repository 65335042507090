import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ActuationAreasTable } from '../../components/molecules/actuationAreasTable';
import { DefaultTemplate } from '../../components/templates/defaultTemplates';

import { Container } from './style';

import { ContextAplication } from '../../context';

export const ActuationAreas = () => {
  const history = useHistory();
  const { handleGet } = useContext(ContextAplication);

  const [ input, setInput ] = useState('');

  const [actuationAreasArray, setActuationAreasArray] = useState(null);

  async function handleGetActuationAreas() {
    const response = await handleGet('actuation-areas');
    setActuationAreasArray(response);
  }

  const filteredActuationAreas = (data) => {
    if (input !== '') {
      const result = data?.filter(({ title }) =>
        title.toLowerCase().includes(input.toLowerCase())
      );

      return result;
    } 
    return data;
  };

  useEffect(() => {
    handleGetActuationAreas();
  }, []);

  return (
    <DefaultTemplate>
      <Container>
        <header>
          <div>
            <h1>Áreas de atuação</h1>
            <button
              className="header-button"
              type="button"
              onClick={() => history.push('actuationareas/create')}
            >Criar</button>
          </div>
          <div>
            <input
              type="text"
              placeholder="Digite sua busca"
              onChange={(e) => setInput(e.target.value)}
            />
          </div>
        </header>

        <body>
          <ActuationAreasTable actuationAreas={filteredActuationAreas(actuationAreasArray)} />
        </body>
      </Container>
    </DefaultTemplate>
  );
};
