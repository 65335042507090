import { Container } from './style';
import { FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export function UserReferralTable({ referralsUsers }) {

  return (
    <Container>
      <table>
        <tr>
          <th>Nome</th>
          <th>Data de entrada no APP</th>
          <th></th>
        </tr>

        {referralsUsers && referralsUsers.length ? referralsUsers.map((referralUser) =>
          <tr>
            <td>{referralUser?.idUser?.name}</td>
            <td>{`${new Date(referralUser?.colectedAt).getDate()}/${new Date(referralUser?.colectedAt).getMonth()}/${new Date(referralUser?.colectedAt).getFullYear()}`}</td>
            <td>
              <Link
                className="showUser__button"
                title="Ver usuário"
                to={`/users/update/${referralUser.idUser._id}`}
              >
                <FaEye
                />
              </Link>
            </td>
          </tr>
        ) :
          <span>Não há usuários indicados.</span>
        }
      </table>
    </Container>
  )
}
