import { makeStyles } from '@material-ui/core/styles';

const useStylesHome = makeStyles((theme) => ({
  container_home: {
    display: 'flex',
    justifyContent: 'space-around',
    height: '100vh',
    width: '100%',
    backgroundColor: '#424242'
  },

  image_container: {
    display: 'flex',
    width: '65%',
    justifyContent: 'center',
    alignItems: 'center',
  },

}));

export default useStylesHome;
