import { FiTrash } from 'react-icons/fi';
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";

import api from '../../../services/axios';

import { AppSettingsListContainer } from './style';
import { CoverImageInput } from './coverImageInput';


const routeDef = {
  list: {
    routine: '/routinespecialties',
    drug: '/drugspecialties',
  },
  selected: {
    routine: '/home-routine',
    drug: '/home-drug',
  },
  create: {
    routine: '/home-create/routine-image-specialties',
    drug: '',
  },
  delete: {
    routine: '/home-delete/routine-image/',
    drug: '/home-delete/drug-image/',
  },
  save_list: {
    routine: '/home-update/routine-image-specialties',
    drug: '/home-update/drug-image-specialties',
  },
};

export function AppSettingsList({
  selectedItems,
  setSelectedItems,
  handleDeleteItems,
  handleGetSelectedItems,
  requestType
}) {

  async function handleSubmitImage(item, image) {
    try {
      await api.put(`home-update/${requestType}-image/${item._id}`, {image: image})
      await handleGetSelectedItems()
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  async function handlePositionChange(from, to) {
    try {
      const a = { ...selectedItems[from], position: to }
      const b = { ...selectedItems[to], position: from }
      
      const response = [a, b].map((specialty) => {
        return { _id: specialty._id, position: specialty.position };
      });
      
      await api.put(routeDef.save_list?.[requestType], response);
      await handleGetSelectedItems()
    } catch (error) {
      
    }
    // toast.success(`Lista de ${(route === 'routine') ? 'ROTINAS' : 'DROGAS'} atualizadas com sucesso!`);
  }


  return (
    <AppSettingsListContainer>
        <ul>
          {selectedItems?.sort((a, b) => a.position - b.position).map((item, index) => (

            <li>
              <button
                disabled={index >= selectedItems.length - 1}
                onClick={() => handlePositionChange(index, index + 1)}>
                <FaArrowAltCircleDown className="listItem__orderArrow"></FaArrowAltCircleDown>
              </button>
              <button
                disabled={index <= 0}
                onClick={() => handlePositionChange(index, index - 1)}>
                <FaArrowAltCircleUp className="listItem__orderArrow up"></FaArrowAltCircleUp>
              </button>

              <CoverImageInput item={item} onSaveImage={handleSubmitImage}/>
              <h3> {item?.title}</h3>
              <button
                className="delete__button"
                onClick={() => handleDeleteItems(item)}
              >
                <FiTrash />
              </button>
            </li>
          ))
          }
        </ul>
    </AppSettingsListContainer >
  )
}
