import { useContext, useEffect, useState } from 'react';
import { useFormik, Field, FieldArray, FormikProvider } from 'formik';
import { useHistory } from 'react-router-dom';

import { FaMinus, FaPlus } from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';

import { InputComponent } from '../../atoms/inputComponent';
import { ContextAplication } from '../../../context';

import { UserFormComponent, FilterContainer } from './style';

export function EmailsForm({ email = null, isCreate = false }) {
  const { handleCreate, handleGet, handleUpdate } =
    useContext(ContextAplication);

  const history = useHistory();

  const [actuationAreas, setActuationAreas] = useState(null);
  const [states, setStates] = useState(null);
  const [usersList, setUsersList] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [whoIsOpen, setWhoIsOpen] = useState(null);


  let timeout = null;

  async function handleGetUsers(path, param) {
    if (path === 'users/auth') {
      const emailsResponse = await handleGet(`auths/email/${param}`);

      let emailsIds = []
      emailsResponse.map(email => emailsIds.push(email._id))

      param = JSON.stringify(emailsIds);
    }
    const response = await handleGet(`${path}/${param}`)
    setUsersList(response)
  }

  function debounceInput(field, param) {
    if (param.length < 3) { return }

    clearTimeout(timeout);
    timeout = setTimeout(async function () {
      setUsersList([]);
      return handleGetUsers(field, param);
    }, 800);
  }

  async function handleGetProps() {
    const response = await handleGet('actuation-areas');
    const responseStates = await handleGet('states');
    const responseTemplates = await handleGet('templates');

    setActuationAreas(response);
    setStates(responseStates);
    setTemplates(responseTemplates);
  }

  useEffect(() => {
    handleGetProps();
  }, []);

  const formik = useFormik({
    initialValues: {
      template_name: email?.template_name || '',
      send_to_all_users: email?.send_to_all_users || true,
      actuation_areas_id: email?.actuation_areas_id || [],
      states_id: email?.states_id || [],
      users_id: email?.users_id || [],
    },

    onSubmit: async (values) => {
      try {
        let response = null;
        let usersArray = [];

        if (values.send_to_all_users) {
          values.users_id = [];
          values.actuation_areas_id = [];
          values.states_id = [];
        } else {
          if (whoIsOpen === 'users') {
            values.actuation_areas_id = [];
            values.states_id = [];

            usersArray = values.users_id.map(user => { return user._id })
          } else if (whoIsOpen === 'filters') {
            values.users_id = [];
          }

        }

        isCreate
          ? (response = await handleCreate('/notifications/send-email', {...values, users_id: usersArray}))
          : (response = await handleUpdate(
            `notifications/update-email/${email?._id}`,
            {...values, users_id: usersArray}
          ));

          return response;
      } catch (error) {
        console.error(error);
      }
    },
  });

  const inputEmail = [
    {
      id: 'send_to_all_users',
      label: 'Enviar para todos os usuários',
      type: 'checkbox',
      name: 'send_to_all_users',
      onChange: formik.handleChange,
      checked: formik.values.send_to_all_users,
      value: formik.values.send_to_all_users,
    }
  ];


  function handleFormSubmit(e) {
    e.preventDefault();
    try {
      formik.handleSubmit();
    } catch (error) {
      console.log(error);
      return;
    }
  }

  return (
    <UserFormComponent onSubmit={handleFormSubmit}>
      <FormikProvider value={formik}>
        <span>Template</span>
        <Field
          component="select"
          name='template_name'
          onChange={formik.handleChange}
          value={formik.values.template_name}
        >
          <option value={null}>Selecione o Template</option>
          {templates?.map((templates) => (
            <option value={templates?.name}>
              {templates?.name}
            </option>
          ))}
        </Field>
        <div>
          {inputEmail &&
            inputEmail.map((prop) => (
              <InputComponent inputProp={prop} />
            ))}
        </div>
        <hr />

        {(
          !formik.values.send_to_all_users && (
            <FilterContainer whoIsOpen={whoIsOpen}>
              <h1>Filtrar por</h1>
              <div className="filters">
                <h3 onClick={() => setWhoIsOpen('filters')}>
                  Áreas de Atuação e Estados
                </h3>
                {actuationAreas && (
                  <FieldArray
                    name="actuation_areas_id"
                    render={(arrayHelpers) => (
                      <div className="select-container">
                        <div className="header">
                          <h2>Selecionar Áreas de Atuação</h2>
                          <button
                            type="button"
                            onClick={() =>
                              arrayHelpers.insert(
                                formik.values.actuation_areas_id.length,
                                ''
                              )
                            } // adiciona mais uma opção de select
                          >
                            <FaPlus />
                          </button>
                        </div>

                        {formik.values.actuation_areas_id &&
                          formik.values.actuation_areas_id.map((_, index) => (
                            <div key={index}>
                              <Field
                                component="select"
                                name={`actuation_areas_id[${index}]`}
                                onChange={formik.handleChange}
                                value={formik.values.actuation_areas_id[index]}
                              >
                                <option value={null}>Adicione</option>
                                {actuationAreas?.map((actuationArea) => (
                                  <option value={actuationArea?._id}>
                                    {actuationArea?.title}
                                  </option>
                                ))}
                              </Field>

                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)} // remove um grupo do array
                              >
                                <FaMinus />
                              </button>
                            </div>
                          ))}
                      </div>
                    )}
                  />
                )}

                {states?.length && (
                  <FieldArray
                    name="states_id"
                    render={(arrayHelpers) => (
                      <div className="select-container">
                        <div className="header">
                          <h2>Selecionar Estados</h2>
                          <button
                            type="button"
                            onClick={() =>
                              arrayHelpers.insert(
                                formik.values.states_id.length,
                                ''
                              )
                            } // adiciona mais uma opção de select
                          >
                            <FaPlus />
                          </button>
                        </div>

                        {formik.values.states_id &&
                          formik.values.states_id.map((_, index) => (
                            <div key={index}>
                              <Field
                                component="select"
                                name={`states_id[${index}]`}
                                onChange={formik.handleChange}
                                value={formik.values.states_id[index]}
                              >
                                <option value={null}>Adicione</option>
                                {states &&
                                  states?.map((state) => (
                                    <option value={state?._id}>
                                      {state?.name}
                                    </option>
                                  ))}
                              </Field>

                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)} // remove um grupo do array
                              >
                                <FaMinus />
                              </button>
                            </div>
                          ))}
                      </div>
                    )}
                  />
                )}
              </div>

              <div className="users">
                <h3 onClick={() => setWhoIsOpen('users')}>Usuários</h3>
                <FieldArray
                  name="users_id"
                  render={(arrayHelpers) => (
                    <div>





                      <div className="header">
                        <h2>Selecionar usuários</h2>
                        <div>
                          <input type='text' placeholder='Busque pelo nome' onChange={(e) => debounceInput('users/name', e.target.value)} />
                          <input type='text' placeholder='Busque pelo e-mail' onChange={(e) => debounceInput('users/auth', e.target.value)} />
                          <input type='text' placeholder='Busque pelo CPF/CNPJ' onChange={(e) => debounceInput('users/document', e.target.value)} />
                        </div>
                      </div>

                      <div>
                        <h3>Usuários selecionados</h3>
                        <div className="userList">
                          {formik.values.users_id.map((user, index) => (
                            <div className="userList__content">
                              <p>{user.name}</p>
                              <p>{user.auth?.email}</p>
                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)} // remove um grupo do array
                              >
                                <FaMinus />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>

                      <hr />
                      <table>
                        <thead>
                          <th>Nome</th>
                          <th>Email</th>
                          <th>Documento</th>
                        </thead>

                        <tbody>
                          {usersList && usersList.map(user => (
                            <tr>
                              <td>{user.name}</td>
                              <td>{user.auth?.email}</td>
                              <td>{user.document}</td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    arrayHelpers.insert(
                                      formik.values.actuation_areas_id.length,
                                      user
                                    )
                                  } // adiciona mais uma opção de select
                                >
                                  <FaPlus />
                                </button>
                              </td>
                            </tr>
                          ))}

                        </tbody>

                      </table>

                    </div>
                  )}
                />
              </div>
            </FilterContainer>
          )
        )}
      </FormikProvider>

      {/* <div>
        {formik.values.content && (
          <div>
            <h1>Prévia do conteúdo do e-mail:</h1>
          </div>
        )}
      </div> */}

      <div className="formButton">
        <button type="button" onClick={() => history.push('/')}>
          Cancelar
        </button>
        <button disabled={formik.isSubmitting} type="submit">
          {formik.isSubmitting ? (
            <CircularProgress size={14} style={{ margin: 0, padding: 0 }} />
          ) : (
            'Enviar'
          )}
        </button>
      </div>
    </UserFormComponent>
  );
}
