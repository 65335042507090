/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { BiCheckDouble } from 'react-icons/bi';
import { CgClose } from 'react-icons/cg';

import { Pagination } from '../../atoms/pagination';

import { Container } from './style';

export function ChangelogTable({ changelogs }) {

  const [page, setPage] = useState(1);
  const [changelogsArray, setChangelogsArray] = useState(null)

  function handleChangelogArray() {
    setChangelogsArray(changelogs?.slice((page-1)*10,page*10))
  }

  useEffect(() => {
    if(changelogs) {
      handleChangelogArray()
    }
  }, [changelogs, page])

  return (
    <Container>
    <table>
        <tr>
          <th>Versão</th>
          <th style={{textAlign: 'center'}}>Possui descrição</th>
          <th></th>
        </tr>

        {changelogsArray && changelogsArray.map((changelog) => (
          <tr>
            <td>{changelog?.version}</td>
            <td>{
              changelog?.change_log ?
                <BiCheckDouble color="green"/> :
                  <CgClose color="red"/> 
            }</td>
            <td style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'inherit'}}>
              <Link to={`/changelog/update/${changelog?._id}`}>
                <AiFillEdit />
              </Link>
            </td>
          </tr>
        ))}
    </table>

    {changelogs?.length > 10 && 
      <Pagination currentPage={page} totalCountOfRegisters={changelogs.length} onPageChange={(e) => setPage(e)}/>
    }
    </Container>
  )
}
